import React from 'react';
import { useWatch } from 'react-hook-form';

const PrefillableInput = ({ control, inputComponent, ...rest }) => {
  const value = useWatch({
    control,
    name: rest?.name,
  });

  return value ? (
    <inputComponent.component
      control={control}
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  ) : (
    <inputComponent.component control={control} {...rest} />
  );
};

export default PrefillableInput;
