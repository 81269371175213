import React from 'react';

import Select from '@material-ui/core/Select';

import styles from './StandardSelect.module.scss';

const modalUnderSelectInputProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const menuProps = {
  ...modalUnderSelectInputProps,
};

const StandardSelect = React.forwardRef(
  (
    {
      classes = styles,
      defaultValue,
      children,
      MenuProps = menuProps,
      ...rest
    },
    ref
  ) => {
    return (
      <Select classes={classes} MenuProps={MenuProps} {...rest}>
        {children}
      </Select>
    );
  }
);

export default StandardSelect;
