import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthUser } from 'utils/AuthUser';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

const LogoutDialog = ({ open = true, closeDialog }) => {
  const { logout } = useAuthUser();
  const { t, ready } = useTranslation(['profile', 'translation']);
  const baseNameProfile = 'profile:';
  const baseName = 'translation:';

  return (
    <ConfirmationDialog
      open={open}
      closeDialog={closeDialog}
      dialogHeader={t(`${baseNameProfile}logout`)}
      request={logout}
      denyButtonLabel={ready ? t(`${baseName}cancel`) : ''}
      acceptButtonLabel={ready ? t(`${baseName}confirm`) : ''}
      error={false}
    >
      {t(`${baseNameProfile}description`)}
    </ConfirmationDialog>
  );
};

export default LogoutDialog;
