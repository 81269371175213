import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import moment from 'moment';

import useToggle from 'hooks/useToggle';
import {
  formOfferObject,
  formActiveOfferObject,
} from 'utils/offers/offersUtils';
import { updateCampaigns } from 'api/campaigns';
import FormModal from 'components/FormModal/FormModal';
import OfferFormContent from 'pages/offers/OfferFormContent';
import { scrollToModalTop } from 'utils/materialUiUtils';

const scope = 'editOffer';

const EditOffer = ({
  offerObject,
  isOfferActive,
  getCampaign,
  singleCampaignLoading,
  singleCampaignError,
  redirect,
  dialogHeader,
}) => {
  const { t, ready } = useTranslation(['offers', 'translation']);
  const [loading, setLoading] = useState(false);
  const {
    value: errorVisible,
    toggle: toggleErrorVisible,
    toggleExactValue: setErrorVisible,
  } = useToggle(false);
  const [error, setError] = useState(null);
  const { campaignId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    formState: { dirtyFields },
    watch,
  } = useForm({
    defaultValues: offerObject,
    shouldUnregister: false,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorVisible(false);
    try {
      const finalData = isOfferActive
        ? formActiveOfferObject(data, offerObject)
        : formOfferObject(data);
      const today = moment().startOf('day').format('X');
      const isBeginDateInPast = finalData.beginDate < today;
      const isEndDateInPast = finalData.endDate < today;
      const isBeginDateChanged = finalData.beginDate !== offerObject.beginDate;
      const isEndDateChanged = finalData.endDate !== offerObject.endDate;
      if (isBeginDateChanged && isBeginDateInPast) {
        setErrorVisible(true);
        scrollToModalTop();
        if (isEndDateInPast) {
          setError({ userMessage: t(`offers:bothDatesError`) });
        } else {
          setError({ userMessage: t(`offers:beginDateError`) });
        }
        setLoading(false);
        return;
      } else if (isEndDateChanged && isEndDateInPast) {
        setErrorVisible(true);
        scrollToModalTop();
        setError({ userMessage: t(`offers:endDateError`) });
        setLoading(false);
        return;
      }
      if (!finalData?.couponsNotificationLimit) {
        setValue('couponsNotificationLimit', 50);
        finalData['couponsNotificationLimit'] = 50;
      }
      await updateCampaigns({ ...finalData, campaignId });
      setErrorVisible(false);
      setLoading(false);
      mutate('/campaigns/list');
      redirect();
    } catch (error) {
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  return (
    <FormModal
      error={error}
      errorScope={scope}
      errorVisible={errorVisible}
      toggleErrorVisible={toggleErrorVisible}
      disableSubmitButton={Object.keys(dirtyFields).length === 0}
      handleSubmit={handleSubmit(onSubmit)}
      loading={loading}
      closeDialog={redirect}
      getRequestLoading={singleCampaignLoading}
      getRequestError={singleCampaignError}
      retryGetRequest={getCampaign}
      header={dialogHeader}
      submitButtonText={ready ? t('translation:save') : ''}
      hasConfirmation={isOfferActive}
      confirmationHeader={
        ready ? t('offers:modal:confirmationEditHeading') : ''
      }
      confirmationBody={ready ? t('offers:modal:confirmationEditBody') : ''}
    >
      <OfferFormContent
        register={register}
        errors={errors}
        control={control}
        defaultValues={offerObject}
        active={isOfferActive}
        setValue={setValue}
        watch={watch}
      />
    </FormModal>
  );
};

export default EditOffer;
