import i18n from 'utils/i18n.js';
import { format, fromUnixTime } from 'date-fns';
import getUnixTime from 'date-fns/getUnixTime';
import moment from 'moment';
import 'moment-timezone';

export const formatDate = (
  date,
  {
    day = true,
    month = true,
    year = true,
    fullYear = true,
    separator = '/',
  } = {}
) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = fullYear
    ? date.getFullYear()
    : date.getFullYear().toString().substring(0, 2);
  const parts = [];
  day && parts.push(dd);
  month && parts.push(mm);
  year && parts.push(yyyy);
  return parts.join(separator);
};

export const fullMonthDate = (
  date,
  { month = 'long', day = 'numeric' } = {}
) => {
  const currentLang = i18n.language;
  return new Date(date).toLocaleDateString(
    currentLang === 'el' ? 'el-GR' : 'en-GB',
    {
      month: month,
      day: day,
    }
  );
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subtractDates = (dateFrom, dateUntil) => {
  return Math.floor((dateUntil - dateFrom) / (60 * 60 * 24 * 1000));
};

export const getDateBeforeDays = (days) => {
  let date = new Date();
  let dateBeforeDays = date - 1000 * 60 * 60 * 24 * parseInt(days);
  dateBeforeDays = new Date(dateBeforeDays);
  return dateBeforeDays;
};

export const getDateDayNumber = (date) => new Date(date).getDate();

export const getDateMonthNumber = (date) => new Date(date).getMonth();

export const getDateYearNumber = (date) => new Date(date).getFullYear();

export const getDateAsDaySlashMonth = (date) => {
  const day = new Date(date).getDate();
  let month = getDateMonthNumber(date) + 1;
  month = month < 10 ? `0${month}` : month;
  return `${day}/${month}`;
};

export const getDateWithSlashes = (date) => {
  const year = (getDateYearNumber(date) + '').slice(-2);
  return `${getDateAsDaySlashMonth(date)}/${year}`;
};

export const formatUnixDate = (date, dateFormat) => {
  if (typeof date === 'number') {
    return format(fromUnixTime(date), dateFormat);
  } else {
    return date;
  }
};

export const dateToUTC = (date) => {
  return getUnixTime(new Date(moment(date).utc().toISOString()));
};

export const dateToGreek = (date) => {
  return moment.tz(date, 'Europe/Athens');
};
