import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';
import { formatUnixDate } from 'utils/dateUtils';
import moment from 'moment';

export default function useOffers() {
  const { data, error } = useSWR('/campaigns/list', fetcher);

  const loading = !data && !error;

  const rows = data ? getTableRows(data.campaigns) : [];

  rows.map((row) => {
    row.beginDate = formatUnixDate(row.beginDate, 'dd/MM/yyyy');
    row.isEditVisible =
      row?.approval?.status !== 'pending' && row.endDate > moment().unix();
    row.endDate = formatUnixDate(row.endDate, 'dd/MM/yyyy');
    row.couponsEndDate = formatUnixDate(row.couponsEndDate, 'dd/MM/yyyy');

    return row;
  });

  return {
    loading,
    error,
    rows,
  };
}
