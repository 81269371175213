import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AlphaBonusWhiteLogo } from 'assets/icons/AlphaBonusWhiteLogo.svg';

import styles from './AuthorizedPrimaryHeader.module.scss';

const logoWidth = '8rem';

const AuthorizedPrimaryHeader = ({ children }) => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link to="/" className={styles.logo}>
          <AlphaBonusWhiteLogo width={logoWidth} />
        </Link>
        <section className={styles.containerRight}>{children}</section>
      </div>
    </header>
  );
};

export default AuthorizedPrimaryHeader;
