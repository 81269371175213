import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import { EmailInput } from 'components/Inputs/EmailInput';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';
import { ConfirmPasswordInput } from 'components/Inputs/ConfirmPasswordInput';
import UserRoleSelect from 'pages/users/UserRoleSelect';
import GeneralMessageBox from 'components/GeneralMessageBox/GeneralMessageBox';
import InfoIcon from '@material-ui/icons/Info';

import styles from './UserFormContent.module.scss';
import UserPartnerSelect from 'pages/users/UserPartnerSelect';

const UserFormContent = ({
  register,
  errors,
  control,
  watch,
  editUser = false,
  translations,
}) => {
  const { t, ready } = useTranslation(['users', 'translation']);
  const baseName = 'users:';

  return (
    <>
      <TextInput
        register={register}
        errors={errors}
        name="firstName"
        label={ready ? t(`${baseName}name`) : ''}
      />
      <TextInput
        register={register}
        errors={errors}
        name="lastName"
        label={ready ? t(`${baseName}surname`) : ''}
      />
      <EmailInput
        register={register}
        errors={errors}
        name="email"
        label={ready ? t(`${baseName}email`) : ''}
        autoComplete="off"
      />
      {editUser ? null : (
        <NewPasswordInput
          register={register}
          errors={errors}
          name="password"
          label={t(`${baseName}password`)}
          description={
            <GeneralMessageBox
              title={ready ? t(`${baseName}password-rules-title`) : ''}
              message={ready ? t(`${baseName}password-rules`) : ''}
              icon={<InfoIcon className={styles.icon} />}
              ableToClose={false}
            />
          }
          autoComplete="new-password"
        />
      )}
      {editUser ? null : (
        <ConfirmPasswordInput
          register={register}
          errors={errors}
          name="repeatPassword"
          comparedInputName="password"
          label={ready ? t(`${baseName}confirm-password`) : ''}
          watch={watch}
          autoComplete="new-password"
        />
      )}
      <UserRoleSelect errors={errors} control={control} />
      {watch('role') === 'user' ? (
        <UserPartnerSelect
          errors={errors}
          control={control}
          defaultValue={editUser ? watch('corporateId') : null}
        />
      ) : null}
    </>
  );
};

export default UserFormContent;
