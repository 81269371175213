export default function downloadExcel(data, name) {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name ? `${name}.xlsx` : 'ExcelFile.xlsx');
  document.body.appendChild(link);
  link.click();
}
