import { PrimaryButton } from 'components/Buttons/Buttons';
import React from 'react';

import styles from './UploadFileInput.module.scss';

const UploadFileInput = ({
  register,
  onChangeHandler,
  fileName = 'No file chosen',
  name,
}) => {
  return (
    <span className={styles.span}>
      <PrimaryButton variant="contained" component="label">
        Upload File
        <input
          ref={register}
          name={name}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={onChangeHandler}
          hidden
        />
      </PrimaryButton>
      <span className={styles.fileNameSpan}>{fileName}</span>
    </span>
  );
};

export default UploadFileInput;
