import React from 'react';
import Button from '@material-ui/core/Button';

import InnerLoader from './InnerLoader';
import styles from './Buttons.module.scss';

const PrimaryButton = (props) => {
  return (
    <Button variant="contained" {...props}>
      {props.children}
    </Button>
  );
};

const SecondaryButton = (props) => {
  return (
    <Button variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

const TextButton = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

const PrimaryButtonLoader = ({ loading, children, ...rest }) => {
  return (
    <InnerLoader loading={loading}>
      <PrimaryButton {...rest}>{children}</PrimaryButton>
    </InnerLoader>
  );
};

const SecondaryButtonLoader = ({ loading, children, ...rest }) => {
  return (
    <InnerLoader loading={loading}>
      <SecondaryButton {...rest}>{children}</SecondaryButton>
    </InnerLoader>
  );
};

const SemiTransparentButton = (props) => {
  const classes = { root: styles.semitransparent };
  return (
    <Button variant="contained" {...props} classes={classes}>
      {props.children}
    </Button>
  );
};

const NoStylesButton = ({ extraClasses, ...rest }) => {
  return (
    <button className={`${styles.emptyButton} ${extraClasses}`} {...rest}>
      {rest.children}
    </button>
  );
};

export {
  PrimaryButton,
  SecondaryButton,
  SemiTransparentButton,
  TextButton,
  PrimaryButtonLoader,
  SecondaryButtonLoader,
  NoStylesButton,
};
