import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import Widget from 'components/Widget/Widget';
import PersonalInfoDenseTable from 'pages/profile/PersonalInfoDenseTable';

const PersonalInfo = () => {
  const { t, ready } = useTranslation(['profile']);
  const baseName = 'profile:';

  if (!ready) return null;

  return (
    <Widget
      size="m"
      title={<WidgetHeader title={t(`${baseName}personal-info`)} />}
    >
      <PersonalInfoDenseTable baseName />
    </Widget>
  );
};

export default PersonalInfo;
