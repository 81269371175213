import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell } from 'components/DenseTable/TableCell';
import TableRow from 'components/DenseTable/TableRow';
import DenseTable from 'components/DenseTable/DenseTable';

export default function DenseTableViewObject({
  headerTitles,
  rows,
  translationBaseName,
  t,
}) {
  const { t: tLocal } = useTranslation(['translation']);
  headerTitles = headerTitles
    ? headerTitles
    : [
        { key: 0, value: tLocal('translation:field') },
        { key: 1, value: tLocal('translation:value') },
      ];
  return (
    <DenseTable headerTitles={headerTitles}>
      {rows.map((row) => (
        <TableRow key={row.field}>
          <TableCell>{t(`${translationBaseName}${row.field}`)}</TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </DenseTable>
  );
}
