import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';

const TextInput = ({
  register = () => {},
  errors = {},
  label,
  required = true,
  name = 'text',
  errorMessage,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  return (
    <StandardInput
      inputRef={register({
        required: {
          value: required,
          message: ready ? t(`${baseName}.required`) : '',
        },
      })}
      label={label}
      name={name}
      type="text"
      errorMessage={errorMessage ? errorMessage : errors[name]?.message}
      error={!!errors[name]}
      {...rest}
    />
  );
};

export { TextInput };
