import React from 'react';

import TranslationText from 'components/TranslationText/TranslationText';

const BooleanText = ({ translationFiles = 'translation', boolean }) => {
  return (
    <TranslationText
      translationKey={boolean ? 'yes' : 'no'}
      translationFiles={translationFiles}
    />
  );
};

export default BooleanText;
