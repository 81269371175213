import React from 'react';

import { useAuthUser } from 'utils/AuthUser';
import AuthorizedHeaderAvatar from 'components/AuthorizedHeaderContent/AuthorizedHeaderAvatar';
import AuthorizedHeaderLinkElement from 'components/AuthorizedHeaderContent/AuthorizedHeaderLinkElement';

const HeaderProfile = ({ page }) => {
  const { user } = useAuthUser();

  return (
    <AuthorizedHeaderLinkElement
      page={page}
      content={user && user.firstName ? user.firstName : ''}
      icon={<AuthorizedHeaderAvatar user={user} />}
    />
  );
};

export { HeaderProfile };
