import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchWithLabel = ({
  checked,
  label,
  handleClick,
  disabled = false,
  labelPlacement = 'end',
  className,
  switchClassName,
}) => {
  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            color="primary"
            name="status"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            label={label}
            onClick={handleClick}
            disabled={disabled}
            className={switchClassName}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </div>
  );
};

export default SwitchWithLabel;
