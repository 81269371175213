import React from 'react';
import { useLocalizedError } from 'hooks/useLocalizedError';

import GeneralMessageBox from './GeneralMessageBox';

const GeneralMessageBoxWrapper = ({
  error,
  scope,
  errorParams = {},
  show = true,
  containerClass,
  closeBox,
  title,
  message,
  success,
  ...rest
}) => {
  const {
    title: localisedErrorTitle,
    message: localisedErrorMessage,
  } = useLocalizedError({
    error,
    scope,
    params: errorParams,
  });

  return success ? (
    <GeneralMessageBox
      title={title}
      message={message}
      show={show}
      containerClass={containerClass}
      closeBox={closeBox}
      success={success}
      {...rest}
    />
  ) : (
    <GeneralMessageBox
      title={title ? title : localisedErrorTitle}
      message={message ? message : localisedErrorMessage}
      show={show}
      containerClass={containerClass}
      closeBox={closeBox}
      success={success}
      {...rest}
    />
  );
};

export default GeneralMessageBoxWrapper;
