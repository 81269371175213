import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { validateOrShowError, validateDateRangePicker } from 'utils/validators';

import styles from './InlineDatePicker.module.scss';

const InlineDatePicker = ({
  startDateId = 'startDate',
  endDateId = 'endDate',
  startDate = '',
  endDate = '',
  control,
  name,
  minDate,
  maxDate,
  startDateLabel,
  endDateLabel,
  errors,
}) => {
  const [focusedInput, setFocusedInput] = useState();
  const falseFunc = () => false;

  const { t, ready } = useTranslation(['inputs', 'coupons']);
  const baseName = 'inputs:errors';
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          const checkRequired = validateOrShowError(
            value,
            validateDateRangePicker,
            ready ? t(`${baseName}.both-values-required`) : ''
          );
          if (checkRequired !== true) {
            return checkRequired;
          }
          const { startDate, endDate } = value;

          const startDatePlusAMonth = moment(startDate)
            .startOf('day')
            .add(1, 'month');

          if (moment(endDate).endOf('day').isAfter(startDatePlusAMonth)) {
            return ready ? t('coupons:awardedReport.moreThanMonth') : '';
          }
          return true;
        },
      }}
      render={({ onChange, value }) => (
        <div className={styles.container}>
          <DateRangePicker
            startDate={startDate ? moment(startDate) : value?.[startDateId]}
            endDate={endDate ? moment(endDate) : value?.[endDateId]}
            startDateId={startDateId}
            endDateId={endDateId}
            onDatesChange={(value) => {
              onChange({
                [startDateId]: value?.[startDateId],
                [endDateId]: value?.[endDateId],
              });
            }}
            isOutsideRange={falseFunc}
            minDate={minDate}
            maxDate={maxDate}
            focusedInput={focusedInput}
            onFocusChange={(focus) => setFocusedInput(focus)}
            numberOfMonths={1}
            startDatePlaceholderText={startDateLabel}
            endDatePlaceholderText={endDateLabel}
          />
          <small className={styles.error}>{errors[name]?.message}</small>
        </div>
      )}
    ></Controller>
  );
};

export default InlineDatePicker;
