import React from 'react';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';

import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import TableContainer from 'components/TableContainer/TableContainer';
import { getTableColumns } from 'utils/tablesUtils';
import useUsersApprovals from 'data/useUsersApprovals';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import useDialog from 'hooks/useDialog';
import downloadExcel from 'utils/downloadExcel';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import { getApprovalsUsersExcelFile } from 'api/approvals';

import styles from '../../styles/components/pagesWithTable.module.scss';

const ApprovalsUsers = ({ children }) => {
  const { t, ready } = useTranslation([
    'usersApprovals',
    'offersApprovals',
    'translation',
    'datagrid',
    'users',
  ]);
  const translation = ready
    ? {
        pageTitle: t(`usersApprovals:pageTitle`),
        titles: t(`usersApprovals:titles`, { returnObjects: true }),
        sorting: t(`datagrid:sortingDisclaimer`, { stars: '*' }),
        errorButton: t(`translation:retry`),
        downloadButtonLabel: t(`usersApprovals:downloadButtonLabel`),
      }
    : '';

  const { rows, loading: tableLoading } = useUsersApprovals();
  const columns = ready
    ? getTableColumns('usersApprovals', translation?.titles)
    : [];

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestExcel = async () => {
    startLoading();
    try {
      const response = await getApprovalsUsersExcelFile();
      downloadExcel(response.data, `Users Approvals List`);
      stopLoading();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={tableLoading}
        disclaimerElements={[<span key={1}>{translation?.sorting}</span>]}
        pageName="ApprovalsUsers"
      >
        <h1 className={styles.title}>{translation?.pageTitle}</h1>
        <div className={styles.buttonsContainer}>
          <PrimaryButtonLoader
            className={styles.button}
            aria-label={translation?.downloadButtonLabel}
            title={translation?.downloadButtonLabel}
            onClick={requestExcel}
            loading={isLoading}
          >
            <GetAppIcon />
          </PrimaryButtonLoader>
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation?.downloadButtonLabel}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default ApprovalsUsers;
