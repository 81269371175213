import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StorefrontIcon from '@material-ui/icons/Storefront';

import { HeaderProfile } from 'components/AuthorizedHeaderContent/AuthorizedHeaderElements';
import AuthorizedHeaderLinkElement from 'components/AuthorizedHeaderContent/AuthorizedHeaderLinkElement';
import AuthorizedHeaderMenuElement from 'components/AuthorizedHeaderContent/AuthorizedHeaderMenuElement';

import styles from 'components/AuthorizedHeaderContent/AuthorizedHeaderElements.module.scss';

const approvalsSubNav = [
  { path: '/approvals/offers', translationText: 'header:offers' },
  { path: '/approvals/coupons', translationText: 'header:coupons' },
  { path: '/approvals/users', translationText: 'header:users' },
];

export const configSubNav = {
  approvals: {
    super_admin: [...approvalsSubNav],
    admin: [...approvalsSubNav],
  },
};

const elements = {
  offers: {
    component: AuthorizedHeaderLinkElement,
    props: {
      page: '/offers',
      translationText: 'header:offers',
      icon: <AssignmentIcon classes={{ root: styles.MuiSvgIconRoot }} />,
    },
    name: 'offers',
  },

  users: {
    component: AuthorizedHeaderLinkElement,
    props: {
      page: '/users',
      translationText: 'header:users',
      icon: <PeopleOutlineIcon classes={{ root: styles.MuiSvgIconRoot }} />,
    },
    name: 'users',
  },
  coupons: {
    component: AuthorizedHeaderLinkElement,
    props: {
      page: '/coupons',
      translationText: 'header:coupons',
      icon: <LocalOfferIcon classes={{ root: styles.MuiSvgIconRoot }} />,
    },
    name: 'coupons',
  },
  approvals: {
    component: AuthorizedHeaderMenuElement,
    props: {
      translationText: 'header:approvals',
      icon: <DoneAllIcon classes={{ root: styles.MuiSvgIconRoot }} />,
    },
    name: 'approvals',
  },
  partners: {
    component: AuthorizedHeaderLinkElement,
    props: {
      page: '/partners',
      translationText: 'header:partners',
      icon: <StorefrontIcon classes={{ root: styles.MuiSvgIconRoot }} />,
    },
    name: 'partners',
  },
  profile: {
    component: HeaderProfile,
    props: {
      page: '/profile',
    },
    name: 'profile',
  },
};

export const configNav = {
  super_admin: [
    elements.offers,
    elements.users,
    elements.coupons,
    elements.partners,
    elements.approvals,
    elements.profile,
  ],
  admin: [
    elements.offers,
    elements.users,
    elements.coupons,
    elements.partners,
    elements.approvals,
    elements.profile,
  ],
  manager: [
    elements.offers,
    elements.users,
    elements.coupons,
    elements.partners,
    elements.profile,
  ],
  user: [elements.profile],
};
