import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  darkBlue: '#36495E',
  darkBlueFaded: 'rgb(54, 73, 94, 0.9)',
  lightGray: '#f0f0f0;',
  black: '#303436',
  white: 'white',
  green: '#2db993',
  orange: '#f7ab3f',
  cyan: '#02bbf0',
  grayDisabled: 'rgba(0, 0, 0, 0.38)',
};

const MuiButtonBaseProperties = {
  borderRadius: '0.25rem',
  fontWeight: '700',
  lineHeight: '1.25rem',
  minHeight: '2.5rem',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.darkBlue,
    },
  },
  typography: {
    fontFamily: `'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'sans-serif';`,
    button: {
      fontSize: '1rem',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: colors.white,
      },
      elevation1: {
        boxShadow: 0,
      },
      rounded: {
        borderRadius: '10px',
      },
    },
    MuiSelect: {
      icon: {
        color: 'transparent',
        right: '0.5rem',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      root: {
        background: colors.darkBlue,
      },
    },
    MuiListItemIcon: {
      root: {
        color: colors.white,
        '& svg': {
          color: colors.white,
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        color: colors.white,
        background: colors.darkBlue,
        lineHeight: '1.25rem',
        minHeight: '2.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingRight: '1rem',
      },
    },
    MuiButton: {
      root: {
        '&&:hover': {
          backgroundColor: colors.darkBlue,
          color: colors.white,
        },
        '& svg': {
          color: colors.white,
        },
      },
      contained: {
        color: colors.white,
        ...MuiButtonBaseProperties,
        backgroundColor: colors.darkBlue,
        '&$disabled': {
          color: colors.white,
          backgroundColor: colors.darkBlue,
          opacity: 0.5,
        },
      },
      outlined: {
        color: colors.darkBlue,
        ...MuiButtonBaseProperties,
        border: `0.0625rem solid ${colors.darkBlue}`,
        '&$disabled': {
          color: colors.darkBlue,
          border: `0.0625rem solid ${colors.darkBlue}`,
          opacity: 0.5,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.darkBlue,
        '&$focused': {
          color: colors.darkBlue,
        },
        '&$disabled': {
          color: colors.grayDisabled,
          '&$shrink': {
            color: colors.grayDisabled,
          },
          '&$focused': {
            color: colors.grayDisabled,
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiSvgIcon: {
      root: {
        color: colors.darkBlue,
        backgroundColor: 'transparent',
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.darkBlue,
        paddingLeft: '0.5rem',
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
    },
    MuiTabs: {
      indicator: {
        height: '0.125rem',
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          fontWeight: '700',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: colors.darkBlueFaded,
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: 'none',
        background: colors.white,
        color: colors.darkBlue,
        borderRadius: '1rem',
      },
      paperWidthFalse: {
        maxWidth: '(100%-1rem)',
        margin: '1rem',
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 0,
        },
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiButtonBase: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitTextFillColor: colors.darkBlue,
          WebkitBackgroundClip: 'text',
          caretColor: colors.darkBlue,
        },
        fontWeight: '700',
      },
      root: {
        color: colors.darkBlue,

        '&$disabled': {
          color: colors.grayDisabled,
          '&$focused': {
            color: colors.grayDisabled,
          },
          '&:hover': {
            color: colors.grayDisabled,
          },
        },
      },
      underline: {
        '&:before': {
          borderBottom: `0.0625rem solid ${colors.darkBlue}`,
        },
        '&:after': {
          borderBottom: `0.125rem solid ${colors.darkBlue}`,
        },
        '&&:hover:before': {
          borderBottom: `0.0625rem solid ${colors.darkBlue}`,
        },
        '&$disabled': {
          '&&:before': {
            borderBottom: `0.0625rem solid ${colors.grayDisabled}`,
          },
        },
      },
    },
    MuiDataGrid: {
      root: {
        border: `1px solid ${colors.white}`,
        borderRadius: '1rem',
        padding: '1rem',
        backgroundColor: colors.white,
        color: colors.darkBlue,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
});

export { theme };
