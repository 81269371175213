import useSWR from 'swr';
import { fetcher } from '../api/api';

export function useSingleCampaign(campaignId) {
  const { data, error, mutate, isValidating } = useSWR(
    `/campaigns/single/${campaignId}`,
    fetcher,
    {
      shouldRetryOnError: false,
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );
  const loading = !data && !error;

  return {
    data: data?.campaign,
    loading,
    error,
    mutate,
    isValidating,
  };
}
