import axios from 'axios';
import { apiConfig, requestSuccess, requestError } from 'utils/apiUtils';

export const fetcher = (url) => api.get(url).then((res) => res);

const api = axios.create(apiConfig);

api.interceptors.request.use(
  (config) => requestSuccess(config),
  (error) => requestError(error)
);

api.interceptors.response.use((res) => {
  return res.data.payload;
});

export default api;
