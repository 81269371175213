import api from './api';
import apiBlob from 'api/apiBlob';
import { getFormDataFromJson } from 'utils/json/jsonUtils';
import { asyncInterval } from 'utils/apiUtils';

const createCampaign = async (data) => {
  const result = await api.post(
    '/campaigns/create',
    getFormDataFromJson(data),
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return result?.campaign;
};

const updateCampaigns = (data) => {
  return api.put('/campaigns/update', getFormDataFromJson(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const getSingleOffer = async (campaignId) => {
  const campaignObject = await api.get(`/campaigns/single/${campaignId}`);
  return campaignObject?.campaign;
};

const getSingleOfferCampaign = ({ campaignId }) => {
  return api
    .get(`/campaigns/single/${campaignId}`, { timeout: 9 * 1000 })
    .then((value) => {
      return value?.campaign?.couponsCreated;
    })
    .catch(() => {
      return false;
    });
};

const getCouponsStatus = async (campaignId) => {
  return asyncInterval({ campaignId }, getSingleOfferCampaign, 10 * 1000, 12);
};

const changeCampaignStatus = (data) => {
  return api.put('/campaigns/status', data);
};

const createCampaignCoupons = (data) => {
  return api.post('/campaigns/createcoupons', getFormDataFromJson(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const getWelcomeOfferDetails = async (id) => {
  const welcomeOffer = await api.get(`/campaigns/welcome/${id}`);
  const offerDescriptionLocalizations =
    welcomeOffer?.offers[0]?.offerDescriptionLocalizations;
  const el = offerDescriptionLocalizations.filter(
    (offerDescriptionLocalization) =>
      offerDescriptionLocalization?.localeCode === 'el_GR'
  )[0];
  const en = offerDescriptionLocalizations.filter(
    (offerDescriptionLocalization) =>
      offerDescriptionLocalization?.localeCode === 'en_US'
  )[0];

  return {
    name: el?.offerName || '',
    name_en: en?.offerName || '',
    description: el?.shortDescription || '',
    description_en: en?.shortDescription || '',
    corporateId: welcomeOffer?.offers[0]?.vendorName || '',
    corporateName: welcomeOffer?.offers[0]?.corporateName || '',
    alphaBonusPoints: welcomeOffer?.offers[0]?.debitQuantity || '',
    beginDate: welcomeOffer?.offers[0]?.displayDate,
    endDate: welcomeOffer?.offers[0]?.endDate,
  };
};

const getParticipantsExcelFile = (id) => {
  return apiBlob.get(`/campaigns/participations/excel/${id}`, {
    responseType: 'blob',
  });
};

const getOffersExcelFile = (id) => {
  return apiBlob.get(`/campaigns/excel`, {
    responseType: 'blob',
  });
};

export {
  createCampaign,
  updateCampaigns,
  changeCampaignStatus,
  getSingleOffer,
  createCampaignCoupons,
  getWelcomeOfferDetails,
  getCouponsStatus,
  getParticipantsExcelFile,
  getOffersExcelFile,
};
