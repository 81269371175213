import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CachedIcon from '@material-ui/icons/Cached';

import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import TableContainer from 'components/TableContainer/TableContainer';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import usePartners from 'data/usePartners';
import { getTableColumns } from 'utils/tablesUtils';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { useAuthUser } from 'utils/AuthUser';
import useDialog from 'hooks/useDialog';
import { getPartners } from 'api/partners';

import styles from '../../styles/components/pagesWithTable.module.scss';

const Partners = ({ children }) => {
  const [success, setSuccess] = useState(null);
  const { t, ready } = useTranslation(['partners', 'datagrid', 'translation']);
  const translation = ready
    ? {
        pageTitle: t(`partners:pageTitle`),
        titles: t(`partners:titles`, { returnObjects: true }),
        buttonLabel: t(`partners:buttonLabel`),
        downloadButtonLabel: t(`partners:downloadButtonLabel`),
        dialogSuccessMessage: t(`partners:dialogSuccessMessage`),
        sorting: t(`datagrid:sortingDisclaimer`, { stars: '*' }),
        errorButton: t(`translation:retry`),
        successButton: t(`translation:ok`),
      }
    : '';

  const authUser = useAuthUser();
  const showButton = authUser.user.role === 'super_admin';

  const { rows, loading: tableLoading, error } = usePartners();
  const columns = ready ? getTableColumns('partners', translation?.titles) : [];

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const fetchPartners = async () => {
    startLoading();
    try {
      await getPartners();
      stopLoading();
      setSuccess(true);
      openDialog();
    } catch (error) {
      setSuccess(false);
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={tableLoading}
        error={error}
        disclaimerElements={[<span key={1}>{translation?.sorting}</span>]}
        pageName="Partners"
      >
        <h1 className={styles.title}>{translation?.pageTitle}</h1>
        <div className={styles.buttonsContainer}>
          {showButton ? (
            <PrimaryButtonLoader
              className={styles.button}
              onClick={fetchPartners}
              loading={isLoading}
            >
              <CachedIcon style={{ marginRight: '0.25rem' }} />
              {translation?.buttonLabel}
            </PrimaryButtonLoader>
          ) : (
            ''
          )}
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={success ? closeDialog : fetchPartners}>
            {success ? translation?.successButton : translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation?.downloadButtonLabel}
        isLoading={isLoading}
        success={success}
      >
        {success ? translation?.dialogSuccessMessage : dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default Partners;
