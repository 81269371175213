import React from 'react';

import { MenuItem } from '@material-ui/core';

import styles from './StandardSelectOption.module.scss';

const StandardSelectOption = React.forwardRef(
  ({ children, menuItemClass, ...props }, ref) => {
    return (
      <MenuItem
        ref={ref}
        className={menuItemClass ? menuItemClass : styles.menuItem}
        {...props}
      >
        {children}
      </MenuItem>
    );
  }
);

export default StandardSelectOption;
