import React from 'react';

import LoginWidget from 'pages/login/LoginWidget';
import LoginWrapper from 'pages/login/LoginWrapper';
import LoginWidgetContent from './LoginWidgetContent';

import styles from './Login.module.scss';

const Login = () => {
  return (
    <LoginWrapper containerClass={styles.wrapperContainer}>
      <LoginWidget containerClass={styles.widgetContainer}>
        <LoginWidgetContent />
      </LoginWidget>
    </LoginWrapper>
  );
};

export default Login;
