import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCouponDialog from 'components/CheckCouponDialog/CheckCouponDialog';
import { TextInput } from 'components/Inputs/TextInput';
import { checkCouponCode, redeemCoupon } from 'api/coupons';
import { useForm } from 'react-hook-form';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { useAuthUser } from 'utils/AuthUser';
import useDialog from 'hooks/useDialog';

import styles from './CheckCouponForm.module.scss';

const CheckCouponForm = ({ fullPage }) => {
  const [data, setData] = useState({});
  const [redeemDialog, setRedeemDialog] = useState('close');

  const authUser = useAuthUser();

  const { t, ready } = useTranslation(['coupons']);
  const label = ready ? t(`coupons:label`) : '';
  const button = ready
    ? authUser.user.role === 'user'
      ? t(`coupons:merchant:button`)
      : t(`coupons:check-coupon`)
    : '';

  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    startLoading();
    try {
      const response = await checkCouponCode(data?.checkCoupon);
      setData(response);
      openDialog();
    } catch (error) {
      openDialog();
      setData(error.response.data.error.params);
      handleError(error.response?.data?.error?.userMessage || '');
    }
  };

  const requestCouponRedeeming = async () => {
    startLoading();
    try {
      await redeemCoupon({ code: data.code });
      setRedeemDialog('success');
      stopLoading();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage || '');
      setRedeemDialog('failure');
    }
  };

  const handleCloseDialog = () => {
    setData({});
    setRedeemDialog('close');
    closeDialog();
  };

  return (
    <>
      <form
        className={`${styles.form} ${fullPage ? styles.fullPage : ''}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          register={register}
          name="checkCoupon"
          label={label}
          className={styles.textInput}
          errors={errors}
        />
        <PrimaryButtonLoader
          loading={isLoading}
          disabled={isLoading}
          type="submit"
        >
          {button}
        </PrimaryButtonLoader>
      </form>
      <CheckCouponDialog
        data={data}
        role={authUser.user.role}
        isOpen={isOpen}
        closeDialog={handleCloseDialog}
        errorMessage={dialogErrorMessage}
        success={dialogErrorMessage ? false : true}
        showCloseButton={false}
        error={hasError}
        loading={isLoading}
        redeemRequest={requestCouponRedeeming}
        redeemDialog={redeemDialog}
      />
    </>
  );
};

export default CheckCouponForm;
