import React from 'react';
import TextField from '@material-ui/core/TextField';

import styles from './Inputs.module.scss';

const StandardInput = ({
  errorMessage = '',
  label = '',
  endAdornment = null,
  readOnly = false,
  disabled = false,
  maxLength = null,
  fullWidth = true,
  textfieldClassname,
  description,
  descriptionClassname,
  autoComplete = 'on',
  ...restProps
}) => {
  return (
    <>
      {description && (
        <p className={`${styles.description} ${descriptionClassname || ''}`}>
          {description}
        </p>
      )}
      <TextField
        label={label}
        helperText={errorMessage}
        autoComplete={autoComplete}
        className={`${!description ? styles.textfield : ''} ${
          textfieldClassname || ''
        }`}
        InputProps={{
          endAdornment: endAdornment,
          readOnly: readOnly,
          disabled: disabled,
        }}
        inputProps={{
          maxLength: maxLength,
        }}
        disabled={disabled}
        fullWidth={fullWidth}
        {...restProps}
      />
    </>
  );
};

export { StandardInput };
