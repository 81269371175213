import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import UpdateIcon from '@material-ui/icons/Update';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { cancelCoupon, redeemCoupon } from 'api/coupons';
import useDialog from 'hooks/useDialog';

import styles from '../../styles/components/tableCells.module.scss';

const TableParticipantsActions = ({
  _id,
  campaignId,
  couponCode,
  redeemed: isRedeemed,
  canceled: isCancelled,
  approval,
}) => {
  const [actionType, setActionType] = useState('');

  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const isCancellationPending =
    approval?.status === 'pending' && approval?.action === 'cancel'
      ? true
      : false;

  const requestCouponCancellation = async () => {
    startLoading();
    const data = { participationId: _id };
    try {
      await cancelCoupon(data);
      mutate(`campaigns/participations?campaignId=${campaignId}`);
      closeDialog();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage);
    }
  };

  const requestCouponRedeeming = async () => {
    startLoading();
    const data = { code: couponCode };
    try {
      await redeemCoupon(data);
      mutate(`campaigns/participations?campaignId=${campaignId}`);
      closeDialog();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage || '');
    }
  };

  const { t, ready } = useTranslation(['participants']);
  const translation = ready
    ? {
        actions: t('participants:actionsLabels', { returnObjects: true }),
        modal: t('participants:modal', { returnObjects: true }),
      }
    : '';

  const handleClick = (type) => {
    setActionType(type);
    openDialog();
  };

  return (
    <>
      <div className={styles.container}>
        {isRedeemed || isCancelled ? (
          '-'
        ) : isCancellationPending ? (
          <div className={styles.pending}>
            {translation?.actions?.cancel}
            <UpdateIcon />
          </div>
        ) : (
          <div>
            <PrimaryButton
              className={styles.button}
              onClick={() => handleClick('cancel')}
            >
              {translation?.actions?.cancel}
            </PrimaryButton>
            <PrimaryButton
              className={styles.button}
              onClick={() => handleClick('redeem')}
            >
              {translation?.actions?.redeem}
            </PrimaryButton>
          </div>
        )}
      </div>
      <ConfirmationDialog
        open={isOpen}
        closeDialog={closeDialog}
        dialogHeader={
          actionType === 'cancel'
            ? translation?.modal?.cancellationHeading
            : translation?.modal?.redeemHeading
        }
        isLoading={isLoading}
        request={
          actionType === 'cancel'
            ? requestCouponCancellation
            : requestCouponRedeeming
        }
        error={hasError}
        errorMessage={dialogErrorMessage}
      >
        {actionType === 'cancel'
          ? translation?.modal?.cancellationBody
          : translation?.modal?.redeemBody}
      </ConfirmationDialog>
    </>
  );
};

export default TableParticipantsActions;
