import axios from 'axios';
import storage from 'utils/storage/storage';

const login = ({ email, password }) => {
  return axios.post(
    'auth/local',
    { email, password },
    {
      baseURL: process.env.REACT_APP_AUTH_URL,
      headers: {
        Appid: process.env.REACT_APP_APPID,
      },
    }
  );
};

const logout = () => {
  return axios.post('auth/local/logout', '', {
    baseURL: process.env.REACT_APP_AUTH_URL,
    headers: {
      Appid: 'process.env.REACT_APP_APPID',
      Authorization: `Bearer ${storage.getItem({ key: 'token' })}`,
    },
  });
};

export { login, logout };
