import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

import useUsers from 'data/useUsers';
import downloadExcel from 'utils/downloadExcel';
import useDialog from 'hooks/useDialog';
import { getTableColumns } from 'utils/tablesUtils';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import TableContainer from 'components/TableContainer/TableContainer';
import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import { getUsersExcelFile } from 'api/users';
import { useAuthUser } from 'utils/AuthUser';

import styles from '../../styles/components/pagesWithTable.module.scss';

const Users = ({ children }) => {
  const { t, ready } = useTranslation(['users', 'translation', 'datagrid']);
  const translation = ready
    ? {
        pageTitle: t(`users:pageTitle`),
        titles: t(`users:titles`, {
          returnObjects: true,
        }),
        buttonLabel: t(`users:buttonLabel`),
        downloadButtonLabel: t(`users:downloadButtonLabel`),
        modal: t(`users:modal`, {
          returnObjects: true,
        }),
        errorButton: t(`translation:retry`),
        somethingWrong: t('translation:something-wrong'),
        sorting: t(`datagrid:sortingDisclaimer`, { stars: '*' }),
      }
    : {};

  const authUser = useAuthUser();
  const role = authUser.user.role;

  const { rows, loading, error } = useUsers();
  let columns = ready ? getTableColumns('users', translation?.titles) : '';

  if (ready && role !== 'super_admin') {
    columns = columns.filter((column) => {
      return column.field !== 'actions';
    });
  }

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestExcel = async () => {
    startLoading();
    try {
      const response = await getUsersExcelFile();
      downloadExcel(response.data, 'Users List');
      stopLoading();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={loading}
        error={error}
        disclaimerElements={[<span key={1}>{translation?.sorting}</span>]}
        pageName="Users"
      >
        <h1 className={styles.title}>{translation?.pageTitle}</h1>
        <div className={styles.buttonsContainer}>
          {role === 'super_admin' ? (
            <Link to="users/add">
              <PrimaryButton className={styles.button}>
                <AddIcon />
                {translation?.buttonLabel}
              </PrimaryButton>
            </Link>
          ) : (
            ''
          )}
          <PrimaryButtonLoader
            className={styles.button}
            aria-label={translation?.downloadButtonLabel}
            title={translation?.downloadButtonLabel}
            onClick={requestExcel}
            loading={isLoading}
          >
            <GetAppIcon />
          </PrimaryButtonLoader>
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation?.downloadButtonLabel}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default Users;
