import React from 'react';

import GeneralMessageDialog from 'components/GeneralMessageDialog/GeneralMessageDialog';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorIcon.svg';
import SuccessIcon from 'components/Icons/SuccessIcon';

import styles from './ResponseMessageDialog.module.scss';

const ResponseMessageDialog = ({
  open = true,
  closeDialog,
  success = false,
  children,
  dialogHeader,
  dialogActions,
  isLoading,
}) => {
  return (
    <GeneralMessageDialog
      open={open}
      closeDialog={closeDialog}
      dialogActions={dialogActions}
      dialogHeader={dialogHeader}
      isLoading={isLoading}
    >
      {success ? (
        <SuccessIcon className={styles.icon} />
      ) : (
        <ErrorIcon className={styles.icon} />
      )}
      {children}
    </GeneralMessageDialog>
  );
};

export default ResponseMessageDialog;
