import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import EditUser from 'pages/users/EditUser';
import { getUserById } from 'api/users';

const EditUserWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [userObject, setUserObject] = useState({});
  const [error, setError] = useState(false);

  const history = useHistory();
  const { userId } = useParams();
  const redirect = () => history.push('/users');

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      const { user } = await getUserById(userId);
      setUserObject(user);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return !loading && !error ? (
    <EditUser
      userObject={userObject}
      userLoading={loading}
      userError={error}
      getUser={getUser}
      redirect={redirect}
    />
  ) : (
    <ResponseMessageDialog closeDialog={redirect} isLoading={loading} />
  );
};

export default EditUserWrapper;
