import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthorizedHeaderContent from 'components/AuthorizedHeaderContent/AuthorizedHeaderContent';
import AuthorizedContainer from 'components/AuthorizedContainer/AuthorizedContainer';
import AuthorizedPrimaryHeader from 'components/AuthorizedPrimaryHeader/AuthorizedPrimaryHeader';
import { useAuthUser } from 'utils/AuthUser';
import config from 'data/roles/config';

const AuthorizedLayout = () => {
  const {
    user: { role },
  } = useAuthUser();

  return (
    <AuthorizedContainer>
      <AuthorizedPrimaryHeader>
        <AuthorizedHeaderContent />
      </AuthorizedPrimaryHeader>
      <Switch>
        {config[role].routes.map((route, index) => (
          <Route key={index} exact={route && route.exact} path={route.path}>
            {route?.redirectTo ? (
              <Redirect to={route?.redirectTo ?? '/'} />
            ) : (
              <route.component>
                {route?.subRoutes && route?.subRoutes?.[role] && (
                  <Switch>
                    {route.subRoutes[role].map((subRoute, index) => {
                      return subRoute?.redirectTo ? (
                        <Redirect
                          to={subRoute?.redirectTo || '/'}
                          key={index}
                        />
                      ) : (
                        <Route
                          key={index}
                          exact={subRoute && subRoute.exact}
                          path={subRoute?.path}
                        >
                          {subRoute?.component}
                        </Route>
                      );
                    })}
                  </Switch>
                )}
              </route.component>
            )}
          </Route>
        ))}
        <Route render={() => <Redirect to={config[role]?.defaultUrl} />} />
      </Switch>
    </AuthorizedContainer>
  );
};

export default AuthorizedLayout;
