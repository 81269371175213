import * as React from 'react';

function SuccessIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <g fillRule="nonzero" fill="none">
        <circle fill="#0DB916" cx={10} cy={10} r={9.75} />
        <path
          d="M5.329 8.773a.923.923 0 011.305 0l1.733 1.733 5.223-5.22a.923.923 0 011.305 0l1.081 1.08c.36.36.36.945 0 1.306l-5.863 5.863a.627.627 0 01-.011.011l-1.305 1.305a.923.923 0 01-1.306 0l-3.467-3.467a.923.923 0 010-1.306l1.305-1.305z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SuccessIcon;
