import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';

export default function usePartners() {
  const { data, error } = useSWR('/corporates/list', fetcher);

  const loading = !data && !error;

  const rows = data ? getTableRows(data.corporates) : [];

  rows.map((row, index) => {
    row.corporateKey = index + 1;
    return row;
  });

  return {
    loading,
    error,
    rows,
    data,
  };
}
