import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { useAuthUser } from 'utils/AuthUser';
import { EmailInput } from 'components/Inputs/EmailInput';
import GeneralMessageBoxWrapper from 'components/GeneralMessageBox/GeneralMessageBoxWrapper';

import styles from './LoginWidgetContent.module.scss';

const LoginWidgetContent = () => {
  const { t, ready } = useTranslation(['login', 'translation']);
  const baseName = 'login:';
  const generalName = 'translation:';
  const { login } = useAuthUser();
  const { register, handleSubmit, errors } = useForm();
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async ({ email, password }) => {
    try {
      setLoginLoading(true);
      await login({ email, password });
    } catch (error) {
      setError(error.response?.data?.error);
      setLoginLoading(false);
      setErrorVisible(true);
    }
  };

  return (
    <>
      <h2 className={styles.header}>{ready ? t(`${baseName}welcome`) : ''}</h2>
      <GeneralMessageBoxWrapper
        show={errorVisible}
        closeBox={() => setErrorVisible(false)}
        error={error}
        scope={'login'}
        containerClass={styles.loginErrorBox}
      />
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <EmailInput
          register={register}
          errors={errors}
          name="email"
          label={ready ? t(`${generalName}email`) : ''}
        />
        <PasswordInput
          register={register}
          errors={errors}
          label={ready ? t(`${generalName}password`) : ''}
        />
        <section className={styles.submit}>
          <PrimaryButtonLoader
            loading={loginLoading}
            disabled={loginLoading}
            type="submit"
          >
            {ready ? t(`${baseName}login`) : ''}
          </PrimaryButtonLoader>
        </section>
      </form>
    </>
  );
};

export default LoginWidgetContent;
