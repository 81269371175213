// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as Sentry from '@sentry/react';
import { Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { SWRConfig } from 'swr';

import { theme } from './utils/materialTheme';
import Router from './utils/Router';
import { AuthUserProvider } from './utils/AuthUser';
import AuthorizedRoute from './utils/AuthorizedRoute';
import UnauthorizedRoute from './utils/UnauthorizedRoute';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import Login from 'pages/login/Login';
import IEpage from 'pages/IEpage/IEpage';

import './utils/i18n';

// Load font
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
// Load global styles
import './styles/index.scss';

import * as serviceWorker from './serviceWorker';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
});

ReactDOM.render(
  isIE ? (
    <IEpage />
  ) : (
    <React.StrictMode>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SWRConfig
            value={{
              onErrorRetry: (
                error,
                key,
                config,
                revalidate,
                { retryCount }
              ) => {
                if (error.response?.data?.error?.status === 404) return;
                if (retryCount >= 5) return;
                setTimeout(
                  () => revalidate({ retryCount: retryCount + 1 }),
                  5000
                );
              },
            }}
          >
            <Router>
              <AuthUserProvider>
                <Switch>
                  <UnauthorizedRoute
                    path="/login"
                    redirectTo="/"
                    component={<Login />}
                  />
                  <AuthorizedRoute
                    path="/"
                    redirectTo="/login"
                    component={AuthorizedLayout}
                  />
                  <Redirect to="/" />
                </Switch>
              </AuthUserProvider>
            </Router>
          </SWRConfig>
        </ThemeProvider>
      </StylesProvider>
    </React.StrictMode>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
