import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DayPickerRangeController as DateRangePickerReactDates } from 'react-dates';
import { useWatch } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/el';

import { validateOrShowError, validateDateRangePicker } from 'utils/validators';
import { getDateWithSlashes } from 'utils/dateUtils';

import 'react-dates/initialize';
import './DateRangePicker.scss';
import styles from './DateRangePicker.module.scss';

// If used with react-hook-forms set shouldUnregister: false

const DateRangePicker = ({
  startDateId = 'startDate',
  endDateId = 'endDate',
  startDate = '',
  endDate = '',
  onDatesChange,
  control,
  name = 'DateRangePicker',
  required = true,
  startDatePlaceholderText,
  endDatePlaceholderText,
  errors,
  sectionClassName = styles.section,
  defaultValue,
  isBeginDatePreFilled = false,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const firstFocusedInput = isBeginDatePreFilled ? endDateId : startDateId;
  const [focusedInput, setFocusedInput] = useState(firstFocusedInput);

  const currentValue = useWatch({
    control,
    name: 'DateRangePicker',
    defaultValue: defaultValue,
  });

  useEffect(() => {
    if (!focusedInput) {
      setFocusedInput(startDateId);
    }
  }, [focusedInput, startDateId]);

  return (
    <section className={sectionClassName}>
      <p className={styles.titleParagraph}>
        {`${startDatePlaceholderText} `}
        {currentValue && currentValue?.[startDateId]
          ? `(${getDateWithSlashes(currentValue?.[startDateId])})`
          : ''}
        {` - ${endDatePlaceholderText} `}
        {currentValue && currentValue?.[endDateId]
          ? `(${getDateWithSlashes(currentValue?.[endDateId])})`
          : ''}
      </p>
      <div className={startDate && endDate ? styles.hide : null}>
        <Controller
          control={control}
          name={name}
          rules={{
            validate: (value) =>
              validateOrShowError(
                value,
                validateDateRangePicker,
                ready ? t(`${baseName}.both-values-required`) : ''
              ),
          }}
          defaultValue={{
            [startDateId]: null,
            [endDateId]: null,
          }}
          render={({ onChange, onBlur, value }) => (
            <DateRangePickerReactDates
              startDate={startDate ? moment(startDate) : value?.[startDateId]}
              endDate={endDate ? moment(endDate) : value?.[endDateId]}
              onDatesChange={(value) => {
                onChange({
                  [startDateId]: value?.[startDateId],
                  [endDateId]: value?.[endDateId],
                });
              }}
              focusedInput={focusedInput}
              onFocusChange={(focus) => setFocusedInput(focus)}
              renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <select
                      className={styles.datePickerSelect}
                      value={month.month()}
                      onChange={(e) => {
                        onMonthSelect(month, e.target.value);
                      }}
                    >
                      {moment.months().map((label, value) => (
                        <option
                          key={value}
                          className={styles.datePickerOption}
                          value={value}
                        >
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <select
                      className={styles.datePickerSelect}
                      value={month.year()}
                      onChange={(e) => {
                        onYearSelect(month, e.target.value);
                      }}
                    >
                      <option value={moment().year()}>{moment().year()}</option>
                      <option value={moment().year() + 1}>
                        {moment().year() + 1}
                      </option>
                      <option value={moment().year() + 2}>
                        {moment().year() + 2}
                      </option>
                      <option value={moment().year() + 3}>
                        {moment().year() + 3}
                      </option>
                      <option value={moment().year() + 4}>
                        {moment().year() + 4}
                      </option>
                      <option value={moment().year() + 5}>
                        {moment().year() + 5}
                      </option>
                    </select>
                  </div>
                </div>
              )}
              {...rest}
            />
          )}
        />
        <p className={styles.paragraph}>{errors[name]?.message}</p>
      </div>
    </section>
  );
};

export { DateRangePicker };
