import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSingleOffer } from 'api/campaigns';
import EditOffer from 'pages/offers/EditOffer';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import { deformOfferObject } from 'utils/offers/offersUtils';

const EditOfferWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [offerObject, setOfferObject] = useState({});
  const [error, setError] = useState(false);
  const [isOfferActive, setIsOfferActive] = useState(true);

  const { t, ready } = useTranslation(['offers', 'translation']);
  const history = useHistory();
  const { campaignId } = useParams();

  const baseName = 'offers:';
  const dialogHeader = ready ? t(`${baseName}edit-offer`) : '';
  const redirect = () => history.push('/offers');

  const getCampaign = useCallback(async () => {
    setLoading(true);
    try {
      const initialObject = await getSingleOffer(campaignId);
      const offerObject = await deformOfferObject(initialObject);
      setOfferObject(offerObject);
      setLoading(false);
      setError(false);
      setIsOfferActive(initialObject?.enabled);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, [campaignId]);

  useEffect(() => {
    getCampaign();
  }, [getCampaign]);

  return !loading ? (
    <EditOffer
      offerObject={offerObject}
      isOfferActive={isOfferActive}
      singleCampaignLoading={loading}
      singleCampaignError={error}
      getCampaign={getCampaign}
      redirect={redirect}
      dialogHeader={dialogHeader}
    />
  ) : (
    <ResponseMessageDialog
      closeDialog={redirect}
      dialogHeader={dialogHeader}
      isLoading={loading}
    />
  );
};

export default EditOfferWrapper;
