import React from 'react';
import styles from './IconText.module.scss';

const IconText = ({
  Icon,
  content,
  containerClass = '',
  contentClass = '',
  iconClass = '',
  weight = 'regular',
}) => {
  return (
    <section className={`${styles.container} ${containerClass}`}>
      {Icon && (
        <div className={`${styles['icon-default']} ${iconClass}`}>{Icon}</div>
      )}
      {content && (
        <div className={`${styles[`text-${weight}`]} ${contentClass}`}>
          {content}
        </div>
      )}
    </section>
  );
};

export default IconText;
