import React from 'react';

import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import GeneralMessageBoxWrapper from 'components/GeneralMessageBox/GeneralMessageBoxWrapper';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import DialogContentFallbackWrapper from 'components/DialogContentFallbackWrapper/DialogContentFallbackWrapper';

import styles from './FormModal.module.scss';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useDialog from 'hooks/useDialog';

const FormModal = ({
  error,
  errorScope,
  errorVisible,
  toggleErrorVisible,
  disableSubmitButton = false,
  handleSubmit,
  loading,
  open = true,
  closeDialog,
  getRequestLoading,
  getRequestError,
  retryGetRequest,
  header,
  submitButtonText,
  submitButtonClassName = styles.submitButton,
  hasConfirmation = false,
  confirmationHeader = '',
  confirmationBody = '',
  children,
}) => {
  const { isOpen, openDialog, closeDialog: closeConfirmation } = useDialog(
    false
  );

  const submitForm = () => {
    closeConfirmation();
    handleSubmit();
  };

  return (
    <>
      <DialogOverlay
        open={open}
        closeDialog={closeDialog}
        dialogContentClassname={styles.dialogContent}
        scroll={'body'}
        disableBackdropClick={true}
        dialogHeader={<span className={styles.topHeader}>{header}</span>}
      >
        <DialogContentFallbackWrapper
          error={getRequestError}
          getRequestLoading={getRequestLoading}
          retryGetRequest={retryGetRequest}
        >
          <GeneralMessageBoxWrapper
            show={errorVisible}
            closeBox={toggleErrorVisible}
            error={error}
            scope={errorScope}
            containerClass={styles.registerErrorBox}
          />
          <form className={styles.container} noValidate autoComplete="off">
            {children}

            <section className={submitButtonClassName}>
              <PrimaryButtonLoader
                loading={loading}
                disabled={disableSubmitButton || loading}
                type="button"
                onClick={hasConfirmation ? openDialog : handleSubmit}
              >
                {submitButtonText}
              </PrimaryButtonLoader>
            </section>
          </form>
        </DialogContentFallbackWrapper>
      </DialogOverlay>
      <ConfirmationDialog
        open={isOpen}
        closeDialog={closeConfirmation}
        dialogHeader={confirmationHeader}
        request={submitForm}
      >
        {confirmationBody}
      </ConfirmationDialog>
    </>
  );
};

export default FormModal;
