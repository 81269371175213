import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthUser } from './AuthUser';
import GenericLoader from 'components/GenericLoader/GenericLoader';

/*
  Used for routes that we only want to show
  when the user is not logged in.
*/
const UnauthorizedRoute = ({ component, redirectTo, ...rest }) => {
  const { isLoggedIn, loading } = useAuthUser();

  if (loading) return <GenericLoader />;
  if (isLoggedIn && !loading) {
    return <Redirect push to={redirectTo} />;
  }
  return <Route {...rest}>{component}</Route>;
};

export default UnauthorizedRoute;
