import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import GenericLoader from 'components/GenericLoader/GenericLoader';
import { useAuthUser } from './AuthUser';

const AuthorizedRoute = ({ component, redirectTo, ...rest }) => {
  const { isLoggedIn, loading } = useAuthUser();

  if (loading) return <GenericLoader />;
  if (!isLoggedIn && !loading) return <Redirect push to={redirectTo} />;
  return <Route component={component} {...rest} />;
};

export default AuthorizedRoute;
