import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';

import { TextInput } from 'components/Inputs/TextInput';
import UploadFileInput from 'components/Inputs/UploadFileInput';
import CouponsFormSelect from 'pages/offers/CouponsFormSelect';
import { DateRangePicker } from 'components/Inputs/DateRangePicker';
import { PrefixInput } from 'components/Inputs/PrefixInput';
import GeneralMessageBox from 'components/GeneralMessageBox/GeneralMessageBox';

import styles from './OfferFormContentSecondStep.module.scss';

const selectOptions = [
  { id: 0, value: 'coupons-option-list' },
  { id: 1, value: 'coupons-option-file' },
];
const defaultSelectValue = 0;

const OfferFormContentSecondStep = ({
  register,
  errors,
  control,
  selectValue,
  setSelectValue,
}) => {
  const { t, ready } = useTranslation(['offers', 'translation', 'datagrid']);
  const baseName = 'offers:';
  const translations = ready
    ? {
        name: t(`${baseName}coupon-group-name`),
        name_en: t(`${baseName}coupon-group-name-en`),
        prefix: t(`${baseName}coupon-prefix`),
        startDate: t(`${baseName}start-date`),
        endDate: t(`${baseName}end-date`),
        dates: t(`datagrid:datesDisclaimer`, { stars: '*' }),
        prefixRulesTitle: t(`${baseName}prefix-rules-title`),
        prefixRules: t(`${baseName}prefix-rules`),
      }
    : {};
  const [fileName, setFileName] = useState();

  const onChangeHandler = (event) => {
    setFileName(event?.target?.files[0]?.name);
  };

  const handleSelectChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      setSelectValue(event?.target?.value);
    }
  };

  return (
    <>
      <CouponsFormSelect
        options={selectOptions}
        defaultValue={defaultSelectValue}
        value={selectValue}
        handleChange={handleSelectChange}
      />
      <TextInput
        register={register}
        errors={errors}
        name="name"
        label={translations?.name}
      />

      {selectValue === '0' ? (
        <>
          <GeneralMessageBox
            title={translations?.prefixRulesTitle}
            message={translations?.prefixRules}
            icon={<InfoIcon className={styles.icon} />}
            ableToClose={false}
          />
          <PrefixInput
            register={register}
            errors={errors}
            name="prefix"
            label={translations?.prefix}
            InputLabelProps={{ required: false }}
          />
        </>
      ) : null}
      {selectValue === '1' ? (
        <UploadFileInput
          name="excelFile"
          register={register}
          fileName={fileName}
          onChangeHandler={onChangeHandler}
        />
      ) : null}
      <DateRangePicker
        control={control}
        startDatePlaceholderText={translations?.startDate}
        endDatePlaceholderText={translations?.endDate}
        errors={errors}
        isBeginDatePreFilled={true}
      />
      <small>{translations?.dates}</small>
    </>
  );
};

export default OfferFormContentSecondStep;
