import React from 'react';

import { useAuthUser } from 'utils/AuthUser';
import { configNav, configSubNav } from 'data/roles/configNav';

import styles from './AuthorizedHeaderContent.module.scss';

const AuthorizedHeaderContent = () => {
  const { user } = useAuthUser();
  const { role } = user || {};
  if (!role) {
    return null;
  }
  return (
    <nav className={styles.container}>
      {configNav?.[role] &&
        configNav[role].map((element) => {
          const { name, props } = element || {};
          const links = configSubNav?.[name]?.[role];
          return links ? (
            <element.component key={name} links={links} {...props} />
          ) : (
            <element.component key={name} {...props} />
          );
        })}
    </nav>
  );
};

export default AuthorizedHeaderContent;
