import React from 'react';
import MenuItem from 'components/MenuButton/MenuItem';

const AuthorizedHeaderMenuElementList = React.forwardRef(
  ({ links, t, ready, handleClose }, ref) => {
    return ready && links
      ? links.map((link) => {
          const { path, translationText, text } = link || {};
          return (
            <MenuItem key={path} onClick={() => handleClose(path)}>
              {text ? text : t(translationText)}
            </MenuItem>
          );
        })
      : null;
  }
);

export default AuthorizedHeaderMenuElementList;
