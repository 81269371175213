import React from 'react';
import TableCellMaterial from '@material-ui/core/TableCell';

import styles from './TableCell.module.scss';

const TableCell = ({ children, ...rest }) => (
  <TableCellMaterial classes={{ root: styles.MuiTableCellRoot }} {...rest}>
    {children}
  </TableCellMaterial>
);

const TableCellHeader = ({ children, ...rest }) => (
  <TableCellMaterial
    classes={{ root: styles.MuiTableCellRootHeader }}
    {...rest}
  >
    {children}
  </TableCellMaterial>
);

export { TableCell, TableCellHeader };
