import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InfoIcon from '@material-ui/icons/Info';

import useToggle from 'hooks/useToggle';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { ConfirmPasswordInput } from 'components/Inputs/ConfirmPasswordInput';
import { NewPasswordInput } from 'components/Inputs/NewPasswordInput';
import GeneralMessageBoxWrapper from 'components/GeneralMessageBox/GeneralMessageBoxWrapper';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { setUserPassword } from 'api/users';
import Widget from 'components/Widget/Widget';
import WidgetHeader from 'components/WidgetHeader/WidgetHeader';

import styles from './ChangePassword.module.scss';
import GeneralMessageBox from 'components/GeneralMessageBox/GeneralMessageBox';

const ChangePassword = () => {
  const { t, ready } = useTranslation(['translation', 'profile', 'users']);
  const baseName = 'profile:';
  const translations = ready
    ? {
        currentPassword: t(`${baseName}current-password`),
        newPassword: t(`${baseName}new-password`),
        repeatPassword: t(`${baseName}confirm-password`),
        buttonLoader: t('translation:continue'),
        successMessage: t(`${baseName}password-change-success`),
        passwordRules: t(`users:password-rules`),
        passwordRulesTitle: t(`users:password-rules-title`),
      }
    : {};
  const { register, handleSubmit, watch, errors } = useForm();
  const {
    value: boxVisible,
    toggle: toggleBoxVisibile,
    toggleExactValue: setBoxVisibile,
  } = useToggle(false);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onSubmit = (data) => {
    setBoxVisibile(false);
    setLoading(true);
    setUserPassword(data)
      .then(() => {
        setSuccess(true);
        setBoxVisibile(true);
      })
      .catch((error) => {
        setSuccess(false);
        setBoxVisibile(true);
        setError(error.response?.data?.error);
      })
      .then(() => {
        setLoading(false);
        setBoxVisibile(true);
      });
  };

  return ready ? (
    <Widget
      size="m"
      title={<WidgetHeader title={t(`${baseName}change-password`)} />}
    >
      <GeneralMessageBoxWrapper
        show={boxVisible}
        error={error}
        closeBox={toggleBoxVisibile}
        success={success}
        message={success ? translations?.successMessage : null}
        containerClass={styles.messageBox}
      />
      <GeneralMessageBox
        title={translations?.passwordRulesTitle}
        message={translations?.passwordRules}
        icon={<InfoIcon className={styles.icon} />}
        ableToClose={false}
      />
      <PasswordInput
        register={register}
        errors={errors}
        name="oldPassword"
        label={translations?.currentPassword}
      />
      <NewPasswordInput
        register={register}
        errors={errors}
        name="newPassword"
        label={translations?.newPassword}
      />
      <ConfirmPasswordInput
        register={register}
        errors={errors}
        name="repeatPassword"
        comparedInputName="newPassword"
        label={translations?.repeatPassword}
        watch={watch}
      />
      <section className={styles.buttonContainer}>
        <PrimaryButtonLoader
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          {translations?.buttonLoader}
        </PrimaryButtonLoader>
      </section>
    </Widget>
  ) : null;
};

export default ChangePassword;
