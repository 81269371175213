import { useCallback, useState } from 'react';

export default function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue((current) => !current);
  }, []);

  const toggleExactValue = useCallback((value) => {
    setValue(value);
  }, []);

  return { value, toggle, toggleExactValue };
}
