import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useSingleCampaign } from 'data/useSingleCampaign';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';
import { PrimaryButton } from 'components/Buttons/Buttons';
import DenseTableViewObject from 'components/DenseTableViewObject/DenseTableViewObject';
import DialogContentFallbackWrapper from 'components/DialogContentFallbackWrapper/DialogContentFallbackWrapper';
import {
  dataWithApprovalFieldsAsList,
  singleCampaignAsList,
} from 'utils/offers/offersUtils';

import styles from './ViewOfferModal.module.scss';

const ViewOfferModal = ({
  open = true,
  redirectUrl,
  showPendingFields = false,
  isFromApprovals,
}) => {
  const { t, ready } = useTranslation(['offers', 'translation', 'datagrid']);
  const baseName = 'offers:';
  const translations = ready
    ? {
        edit: t(`translation:edit`),
        dates: t(`datagrid:datesDisclaimer`, { stars: '*' }),
      }
    : {};
  const history = useHistory();

  const { campaignId } = useParams();
  const { data, loading, error, mutate, isValidating } = useSingleCampaign(
    campaignId
  );
  const dataAsList = showPendingFields
    ? dataWithApprovalFieldsAsList(data)
    : singleCampaignAsList(data);

  const redirect = () => history.push(redirectUrl);

  const today = moment().startOf('day').format('X');
  const showEditButton =
    isFromApprovals || data?.endDate < today
      ? false
      : data?.approval?.status !== 'pending';

  return (
    <DialogOverlay
      open={open}
      closeDialog={redirect}
      dialogContentClassname={styles.dialogContent}
      scroll={'body'}
      dialogHeader={t(`${baseName}offer-details`)}
    >
      <DialogContentFallbackWrapper
        error={error}
        getRequestLoading={loading || isValidating}
        retryGetRequest={mutate}
      >
        <section className={styles.section}>
          {dataAsList ? (
            <DenseTableViewObject
              rows={dataAsList}
              translationBaseName={baseName}
              t={t}
            />
          ) : null}
          {showEditButton ? (
            <Link className={styles.link} to={`/offers/${campaignId}/edit/`}>
              <PrimaryButton type="submit">{translations?.edit}</PrimaryButton>
            </Link>
          ) : null}
        </section>
      </DialogContentFallbackWrapper>
    </DialogOverlay>
  );
};

export default ViewOfferModal;
