import { useState } from 'react';

const useDialog = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');

  const openDialog = () => {
    setIsOpen(true);
    setIsLoading(false);
  };

  const closeDialog = () => {
    setIsLoading(false);
    setHasError(false);
    setDialogErrorMessage('');
    setIsOpen(false);
  };

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const handleError = (dialogErrorMessage) => {
    setDialogErrorMessage(dialogErrorMessage);
    setHasError(true);
    setIsLoading(false);
  };

  return {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  };
};

export default useDialog;
