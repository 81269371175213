import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import useToggle from 'hooks/useToggle';
import { formOfferObject } from 'utils/offers/offersUtils';
import { createCampaign, getWelcomeOfferDetails } from 'api/campaigns';
import FormModal from 'components/FormModal/FormModal';
import OfferFormContent from 'pages/offers/OfferFormContent';
import { scrollToModalTop } from 'utils/materialUiUtils';

const scope = 'addOffer';

const AddOffer = () => {
  const { t, ready } = useTranslation(['offers']);
  const baseName = 'offers:';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    value: errorVisible,
    toggle: toggleErrorVisible,
    toggleExactValue: setErrorVisible,
  } = useToggle(false);
  const [error, setError] = useState(null);
  const [isPreMadeOffer, setIsPreMadeOffer] = useState(false);
  const [preMadeOfferBeginDate, setPreMadeOfferBeginDate] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm({
    shouldUnregister: false,
    defaultValues: { couponsType: 'DEFAULT' },
  });

  const redirect = () => history.push('/offers');
  const onSubmit = async (data) => {
    setLoading(true);
    setErrorVisible(false);
    try {
      const finalData = formOfferObject(data);
      const today = moment().startOf('day').format('X');
      const isBeginDateInPast = finalData.beginDate < today;
      const isEndDateInPast = finalData.endDate < today;
      if (isPreMadeOffer) {
        if (isBeginDateInPast && isEndDateInPast) {
          setErrorVisible(true);
          scrollToModalTop();
          setError({ userMessage: t(`offers:bothDatesError`) });
          setLoading(false);
          return;
        } else if (
          finalData.beginDate !== preMadeOfferBeginDate &&
          isBeginDateInPast
        ) {
          setErrorVisible(true);
          scrollToModalTop();
          setError({ userMessage: t(`offers:beginDateError`) });
          setLoading(false);
          return;
        }
      } else {
        if (isBeginDateInPast) {
          setErrorVisible(true);
          scrollToModalTop();
          if (isEndDateInPast) {
            setError({ userMessage: t(`offers:bothDatesError`) });
          } else {
            setError({ userMessage: t(`offers:beginDateError`) });
          }
          setLoading(false);
          return;
        }
      }
      if (!finalData?.couponsNotificationLimit) {
        setValue('couponsNotificationLimit', 50);
        finalData['couponsNotificationLimit'] = 50;
      }
      const response = await createCampaign(finalData);
      setErrorVisible(false);
      setLoading(false);
      const { campaignId } = response || {};
      const { beginDate, endDate } = finalData || {};
      if (campaignId) {
        history.push({
          pathname: `/offers/add/${campaignId}`,
          state: { beginDate, endDate, isPreMadeOffer },
        });
      } else {
        setErrorVisible(true);
        scrollToModalTop();
      }
    } catch (error) {
      console.error(error);
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  const handleWelcomeRequest = async () => {
    setLoading(true);
    setErrorVisible(false);
    try {
      const response = await getWelcomeOfferDetails(getValues('offerId'));
      Object.keys(response).forEach((key) => {
        setValue(key, response[key]);
      });
      setValue('DateRangePicker', {
        startDate: moment(getValues('beginDate')),
        endDate: moment(getValues('endDate')),
      });
      setPreMadeOfferBeginDate(
        moment(getValues('beginDate')).startOf('day').unix()
      );
      setLoading(false);
      setIsPreMadeOffer(true);
    } catch (error) {
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  return (
    <FormModal
      error={error}
      errorScope={scope}
      errorVisible={errorVisible}
      toggleErrorVisible={toggleErrorVisible}
      handleSubmit={handleSubmit(onSubmit)}
      loading={loading}
      closeDialog={redirect}
      header={ready ? t(`${baseName}step-1/2`) + t(`${baseName}add-offer`) : ''}
      submitButtonText={ready ? t('translation:save') : ''}
    >
      <OfferFormContent
        register={register}
        errors={errors}
        control={control}
        request={handleWelcomeRequest}
        loading={loading}
        setValue={setValue}
        watch={watch}
      />
    </FormModal>
  );
};

export default AddOffer;
