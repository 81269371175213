import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';
import { formatUnixDate } from 'utils/dateUtils';

export default function useOffersApprovals() {
  const { data, error } = useSWR('/approvals/campaigns', fetcher);

  const loading = !data && !error;

  const rows = data ? getTableRows(data.approvalCampaigns) : [];

  rows.map((row) => {
    row.updatedAt = formatUnixDate(row.updatedAt, 'dd/MM/yyyy HH:mm');
    row.actionCompletedByFullName = row.actionCompletedBy?.fullName;
    return row;
  });

  return {
    loading,
    error,
    rows,
  };
}
