import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnixDate } from 'utils/dateUtils';

import { PrimaryButton } from 'components/Buttons/Buttons';
import GeneralMessageDialog from 'components/GeneralMessageDialog/GeneralMessageDialog';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorIcon.svg';

import styles from './CheckCouponDialog.module.scss';

const CheckCouponDialog = ({
  data,
  role,
  isOpen,
  closeDialog,
  errorMessage,
  error,
  loading,
  redeemRequest,
  redeemDialog,
}) => {
  const { t, ready } = useTranslation(['coupons']);
  const validHeading = ready ? t(`coupons:modal:headings:valid`) : '';
  const bodies = ready
    ? t(`coupons:modal:bodies`, { returnObjects: true })
    : '';
  const buttons = ready
    ? t(`coupons:modal:buttons`, { returnObjects: true })
    : '';
  const infoTypes = ready
    ? t(`coupons:modal:info`, { returnObjects: true })
    : '';

  const info =
    data !== null
      ? Object.keys(infoTypes).map((infoType, index) => {
          if (data[infoType]) {
            if (typeof data[infoType] === 'number') {
              data[infoType] = formatUnixDate(data[infoType], 'dd/MM/yyyy');
            } //dates process
            return (
              <p key={index}>
                {infoTypes[infoType]} <strong>{data[infoType]}</strong>
              </p>
            );
          }
          return '';
        })
      : '';

  return (
    <>
      {redeemDialog === 'close' ? (
        <GeneralMessageDialog
          open={isOpen}
          closeDialog={closeDialog}
          dialogActions={
            error || role !== 'user' ? (
              <PrimaryButton onClick={closeDialog}>
                {buttons.back}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={closeDialog}>
                  {buttons.cancel}
                </PrimaryButton>
                <PrimaryButton onClick={redeemRequest}>
                  {buttons.redeem}
                </PrimaryButton>
              </>
            )
          }
          dialogHeader={error ? errorMessage : validHeading}
          isLoading={loading}
        >
          {error && data === null ? <ErrorIcon className={styles.icon} /> : ''}
          {info}
          <div className={styles.helper}>
            <p>
              {error && role === 'user'
                ? bodies.merchantError
                : !data && role !== 'user'
                ? bodies.error
                : ''}
            </p>
            {data !== null ? (
              <p>
                <small>{bodies.dateDisclaimer}</small>
              </p>
            ) : (
              ''
            )}
          </div>
        </GeneralMessageDialog>
      ) : (
        <ResponseMessageDialog
          open={isOpen}
          closeDialog={closeDialog}
          success={redeemDialog === 'success' ? true : false}
          dialogHeader={`${infoTypes.name} ${data.name}`}
          dialogActions={
            redeemDialog === 'success' ? (
              <PrimaryButton onClick={closeDialog}>{buttons.ok}</PrimaryButton>
            ) : (
              <PrimaryButton onClick={redeemRequest}>
                {buttons.retry}
              </PrimaryButton>
            )
          }
          isLoading={loading}
        >
          {bodies.redeeming} <strong>{data.code}</strong>
          {redeemDialog === 'success'
            ? bodies.redeemingSuccess
            : bodies.redeemingError}
        </ResponseMessageDialog>
      )}
    </>
  );
};

export default CheckCouponDialog;
