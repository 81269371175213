import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';

const numberPattern = /^[0-9]*$/;

const NumberInput = ({
  register,
  errors,
  label,
  maxLength = 1000,
  minLength = 0,
  name = 'text',
  isRequired = true,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  return (
    <>
      <StandardInput
        inputRef={register({
          required: {
            value: isRequired,
            message: ready ? t(`${baseName}.required`) : '',
          },
          maxLength: {
            value: maxLength,
            message: ready
              ? t(`${baseName}.maxLengthNumber`, { max: maxLength })
              : '',
          },
          minLength: {
            value: minLength,
            message: ready
              ? t(`${baseName}.minLengthNumber`, { min: minLength })
              : '',
          },
          pattern: {
            value: numberPattern,
            message: ready ? t(`${baseName}.isNotNumber`) : '',
          },
        })}
        label={label}
        name={name}
        type="text"
        errorMessage={errors[name]?.message}
        error={!!errors[name]}
        {...rest}
      />
    </>
  );
};

export { NumberInput };
