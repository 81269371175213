import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';
import { formatUnixDate } from 'utils/dateUtils';

export default function useUsersApprovals() {
  const { data, error } = useSWR('/approvals/users', fetcher);

  const loading = !data && !error;

  const rows = data ? getTableRows(data.approvalUsers) : [];

  rows.map((row, index) => {
    row.userApprovalKey = index + 1;
    row.updatedAt = row?.updatedAt
      ? formatUnixDate(row.updatedAt, 'dd/MM/yyyy')
      : '-';
    row.userFullName = row?.user?.fullName ?? '-';
    row.email = row?.user?.email ?? '-';
    row.role = row?.user?.role;
    row.createdByFullName = row?.createdBy?.fullName ?? '-';
    row.actionCompletedByFullName = row?.actionCompletedBy?.fullName ?? '-';
    row.corporateName = row?.user?.corporateName ?? '-';
    return row;
  });
  return {
    loading,
    error,
    rows,
  };
}
