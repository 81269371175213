import useSWR from 'swr';
import { formatUnixDate } from 'utils/dateUtils';
import { getTableRows } from 'utils/tablesUtils';
import { fetcher } from '../api/api';

export default function useCoupons() {
  const { data, error } = useSWR('/campaigns/coupons/list', fetcher);

  const loading = !data && !error;

  const rows = data && data?.coupons ? getTableRows(data.coupons) : [];

  rows.map((row) => {
    const { campaignId, corporateName, endDate, name, offerId, offerType } =
      row?._id || {};
    row.campaignId = campaignId;
    row.corporateName = corporateName;
    row.endDate = formatUnixDate(endDate, 'dd/MM/yyyy');
    row.name = name;
    row.offerId = offerId;
    row.offerType = offerType;
    row.notRedeemed = parseInt(row?.total) - parseInt(row?.redeemed);
    return row;
  });

  return {
    loading,
    error,
    rows,
  };
}
