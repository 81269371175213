import api from './api';
import apiBlob from 'api/apiBlob';

const getUser = () => {
  return api.get('users/me');
};

const setUserPassword = (userInfo) => {
  return api.put('/users/password', userInfo);
};

const deleteUser = (id) => {
  return api.delete(`/users/${id}`);
};

const createUser = (user) => {
  return api.post('/users/register', user);
};

const updateUser = (user) => {
  return api.put('/users/update', user);
};

const getUserById = (_id) => {
  return api.get(`/users/single/${_id}`);
};

const getUsersExcelFile = () => {
  return apiBlob.get(`users/excel`, {
    responseType: 'blob',
  });
};

export {
  getUser,
  deleteUser,
  getUsersExcelFile,
  createUser,
  updateUser,
  getUserById,
  setUserPassword,
};
