import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardInput } from './Inputs';
import {
  validateDoesNotContainSpecialSymbols,
  validateHasAtLeastOneSymbol,
  validateOrShowMultipleErrors,
} from 'utils/validators';
import { PasswordEndAdornment } from 'components/Inputs/PasswordEndAdornment';

const NewPasswordInput = ({
  register,
  errors,
  name = 'newPassword',
  label,
  autoComplete = 'on',
  maxLength = 24,
  minLength = 8,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const [showPassword, setShowPassword] = React.useState(false);

  if (!ready) return null;

  return (
    <>
      <StandardInput
        inputRef={register({
          required: { value: true, message: t(`${baseName}.required`) },
          minLength: {
            value: 8,
            message: t(`${baseName}.minLengthNumber`, { min: minLength }),
          },
          maxLength: {
            value: 24,
            message: t(`${baseName}.maxLengthNumber`, { max: maxLength }),
          },
          validate: (value) =>
            validateOrShowMultipleErrors(value, [
              {
                validateFunction: validateHasAtLeastOneSymbol,
                errorMessage: t(`${baseName}.confirm-password-rules.1`),
              },
              {
                validateFunction: validateDoesNotContainSpecialSymbols,
                errorMessage: t(`${baseName}.confirm-password-rules.2`),
              },
            ]),
        })}
        label={label}
        type={showPassword ? 'text' : 'password'}
        errorMessage={errors[name]?.message}
        error={!!errors[name]}
        name={name}
        autoComplete={autoComplete}
        endAdornment={
          <PasswordEndAdornment
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        }
        {...rest}
      />
    </>
  );
};

export { NewPasswordInput };
