import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AlphaBonusLogo } from 'assets/icons/ΑlphaBonusLogo.svg';
import chrome from 'assets/browsers/chrome.png';
import edge from 'assets/browsers/edge.png';
import firefox from 'assets/browsers/firefox.png';
import safari from 'assets/browsers/safari.png';

import styles from './IEpage.module.scss';

const IEpage = () => {
  const { t, ready } = useTranslation(['IEpage']);
  const translation = ready
    ? {
        title: t(`IEpage:title`),
        subtitle: t(`IEpage:subtitle`),
        content: t(`IEpage:content`),
        linksTitle: t(`IEpage:linksTitle`),
      }
    : '';

  const browsers = [
    { name: 'Chrome', link: 'https://www.google.com/chrome/', src: chrome },
    { name: 'Edge', link: 'https://www.microsoft.com/edge', src: edge },
    {
      name: 'Firefox',
      link: 'https://www.mozilla.org/firefox/new/',
      src: firefox,
    },
    {
      name: 'Safari',
      link: 'https://support.apple.com/downloads/safari',
      src: safari,
    },
  ];

  const logoWidth = '12.5rem';

  return (
    <section className={styles.pageContainer}>
      <AlphaBonusLogo width={logoWidth} />
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>{translation.title}</h1>
        <h2 className={styles.subtitle}>{translation.subtitle}</h2>
        <p className={styles.content}>{translation.content}</p>
        <p className={styles.content}>{translation.linksTitle}</p>
      </div>
      <div className={styles.linksContainer}>
        {browsers.map((browser, index) => (
          <a
            className={styles.browserLink}
            href={browser.link}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <img
              className={styles.imgLink}
              src={browser.src}
              alt={browser.name}
            />
            <span>{browser.name}</span>
          </a>
        ))}
      </div>
    </section>
  );
};

export default IEpage;
