import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import ChangePassword from 'pages/profile/ChangePassword';
import PersonalInfo from 'pages/profile/PersonalInfo';

const ProfileMain = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/`} exact>
        <Redirect to={`${match.url}/personal-details`} />
      </Route>
      <Route path={`${match.url}/personal-details`}>
        <PersonalInfo />
      </Route>
      <Route path={`${match.url}/change-password`}>
        <ChangePassword />
      </Route>
      <Route path={`${match.url}/logout`}></Route>
    </Switch>
  );
};

export default ProfileMain;
