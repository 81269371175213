export const roleSelectOptions = [
  { key: 0, value: 'super_admin', tranaslationText: 'users:roles.super_admin' },
  { key: 1, value: 'admin', tranaslationText: 'users:roles.admin' },
  { key: 2, value: 'manager', tranaslationText: 'users:roles.manager' },
  { key: 3, value: 'user', tranaslationText: 'users:roles.user' },
];

export const formUserObject = ({ _id, email, firstName, lastName, role }) => {
  return { _id, email, firstName, lastName, role };
};
