import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconText from 'components/IconText/IconText';

import styles from './AuthorizedHeaderElements.module.scss';

const AuthorizedHeaderLinkElement = ({
  page,
  icon,
  content,
  translationText,
  linkClass = styles.link,
  contentClass = styles.iconTextContentWrapper,
  iconClass = styles.icon,
}) => {
  const { t, ready } = useTranslation(['header']);
  return (
    <Link key={page} to={page} className={linkClass}>
      <IconText
        key={page + 'iconText'}
        contentClass={contentClass}
        iconClass={iconClass}
        Icon={icon}
        content={ready && translationText ? t(translationText) : content}
      />
    </Link>
  );
};

export default AuthorizedHeaderLinkElement;
