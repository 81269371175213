import React from 'react';

import ProfileMain from './ProfileMain';
import ProfileRightSidebar from './ProfileRightSidebar';
import TwoColumnsLayout from 'layouts/TwoColumnsLayout';

const Profile = () => {
  return (
    <TwoColumnsLayout main={<ProfileMain />} right={<ProfileRightSidebar />} />
  );
};

export default Profile;
