import { useTranslation } from 'react-i18next';

/* Fallback mechanism
 * First look in specific file
 * then look in generic errors file
 * finally show generic error [/errors/generic: general]
 */
const useLocalizedError = ({
  error,
  errorCodeField = 'sfdsf',
  scope = 'generic',
  params = {},
  localizedError = false,
}) => {
  const filePath = 'errors/';
  const { userMessage } = error || {};

  const errorCode =
    error && error[errorCodeField] ? error[errorCodeField] : 'general';

  const translations = [`${filePath}generic`];
  const titleKeys = [
    `${filePath}generic:${errorCode}.title`,
    `${filePath}generic:general.title`,
  ];
  const messageKeys = [
    `${filePath}generic:${errorCode}.message`,
    `${filePath}generic:general.message`,
  ];

  if (scope !== 'generic') {
    translations.unshift(`${filePath}${scope}`);
    titleKeys.unshift(`${filePath}${scope}:${errorCode}.title`);
    messageKeys.unshift(`${filePath}${scope}:${errorCode}.message`);
  }

  const { t, ready } = useTranslation(translations);

  const localisedMessage = ready ? t(messageKeys, params) : '';

  const defaultMessage = userMessage ? userMessage : localisedMessage;

  const title = ready ? t(titleKeys, params) : '';
  const message = localizedError ? localisedMessage : defaultMessage;

  return { title, message };
};

export { useLocalizedError };
