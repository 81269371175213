import React from 'react';

import styles from './AvatarInitials.module.scss';

const AvatarInitials = ({ name, lastname }) => {
  return (
    <figure className={styles.figure}>
      <span className={styles.span}>
        {`${name && name[0] ? name[0] : ''}${
          lastname && lastname[0] ? lastname[0] : ''
        }`}
      </span>
    </figure>
  );
};

export default AvatarInitials;
