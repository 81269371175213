import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../styles/components/tableOverlay.module.scss';

const TableErrorMessage = () => {
  const { t, ready } = useTranslation(['datagrid']);
  const message = ready ? t(`datagrid:error`) : '';

  return <h2 className={styles.message}>{message}</h2>;
};

export default TableErrorMessage;
