import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'components/Buttons/Buttons';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import TableContainer from 'components/TableContainer/TableContainer';
import useOffers from 'data/useOffers';
import { getTableColumns } from 'utils/tablesUtils';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { getOffersExcelFile } from 'api/campaigns';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import useDialog from 'hooks/useDialog';
import downloadExcel from 'utils/downloadExcel';

import styles from '../../styles/components/pagesWithTable.module.scss';

const Offers = ({ children }) => {
  const { t, ready } = useTranslation(['offers', 'datagrid', 'translation']);
  const translation = ready
    ? {
        pageTitle: t(`offers:pageTitle`),
        titles: t(`offers:titles`, { returnObjects: true }),
        buttonLabel: t(`offers:buttonLabel`),
        downloadButtonLabel: t(`offers:downloadButtonLabel`),
        sorting: t(`datagrid:sortingDisclaimer`, { stars: '*' }),
        errorButton: t(`translation:retry`),
      }
    : '';

  const { rows, loading: tableLoading, error } = useOffers();
  const columns = ready ? getTableColumns('offers', translation?.titles) : [];

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestExcel = async () => {
    startLoading();
    try {
      const response = await getOffersExcelFile();
      downloadExcel(response.data, `Offers List`);
      stopLoading();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={tableLoading}
        error={error}
        disclaimerElements={[
          <span key={1}>{translation?.sorting}</span>,
          <span key={2}>{translation?.dates}</span>,
        ]}
        pageName="Offers"
      >
        <h1 className={styles.title}>{translation?.pageTitle}</h1>
        <div className={styles.buttonsContainer}>
          <Link to="offers/add">
            <PrimaryButton className={styles.button}>
              <AddIcon />
              {translation?.buttonLabel}
            </PrimaryButton>
          </Link>
          <PrimaryButtonLoader
            className={styles.button}
            aria-label={translation?.downloadButtonLabel}
            title={translation?.downloadButtonLabel}
            onClick={requestExcel}
            loading={isLoading}
          >
            <GetAppIcon />
          </PrimaryButtonLoader>
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation?.downloadButtonLabel}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default Offers;
