import React from 'react';

import InnerLoader from 'components/Buttons/InnerLoader';

import { colors } from 'utils/materialTheme';
import styles from './TableLoadingOverlay.module.scss';

const TableLoadingOverlay = () => {
  const darkBlue = colors.darkBlue;
  const size = 128;

  return (
    <div className={styles.tableLoaderContainer}>
      <InnerLoader loading={true} size={size} color={darkBlue} />
    </div>
  );
};

export default TableLoadingOverlay;
