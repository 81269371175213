import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar, elGR } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';

import TableLoadingOverlay from 'components/TableLoadingOverlay/TableLoadingOverlay';
import TableNoRowsMessage from 'components/TableNoRowsMessage/TableNoRowsMessage';
import TableErrorMessage from 'components/TableErrorMessage/TableErrorMessage';

import styles from 'components/TableContainer/TableContainer.module.scss';
import 'styles/overrides/material/_datagrid.scss';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const TableContainer = ({
  children,
  tableData,
  loading,
  error,
  disclaimerElements,
  pageName,
}) => {
  const [stored, setStored] = useState(() =>
    JSON.parse(localStorage.getItem(`${pageName}`))
  );
  const { rows, columns } = tableData;

  const columnFields = columns.map((column) => column.field);

  let sortModel;
  let filterModel;
  if (stored?.sortModel && columnFields.includes(stored?.sortModel[0]?.field)) {
    sortModel = stored?.sortModel;
  } else {
    sortModel = [];
  }
  if (
    stored?.filterModel &&
    columnFields.includes(stored?.filterModel?.items[0]?.columnField)
  ) {
    filterModel = stored?.filterModel;
  } else {
    filterModel = null;
  }

  const { t, ready } = useTranslation(['datagrid']);
  const labels = ready ? t(`datagrid:labels`, { returnObjects: true }) : '';
  const overlay = error ? TableErrorMessage : TableNoRowsMessage;

  useEffect(() => {
    return () => {
      localStorage.setItem(`${pageName}`, JSON.stringify(stored));
    };
  }, [stored, pageName]);

  const localeText = {
    ...elGR.props.MuiDataGrid.localeText,
    toolbarFilters: ready ? labels.toolbarFilters : '',
    toolbarDensity: ready ? labels.toolbarDensity : '',
    toolbarDensityCompact: ready ? labels.toolbarDensityCompact : '',
    toolbarDensityStandard: ready ? labels.toolbarDensityStandard : '',
    toolbarDensityComfortable: ready ? labels.toolbarDensityComfortable : '',
  };

  return (
    <div className={styles.tableContainer}>
      {children}
      <div className={styles.gridContainer}>
        <ErrorBoundary>
          <DataGrid
            loading={loading}
            components={{
              LoadingOverlay: TableLoadingOverlay,
              NoRowsOverlay: overlay,
              Toolbar: GridToolbar,
            }}
            rows={rows}
            columns={columns}
            page={stored?.page || 0}
            pageSize={rows.length < 25 ? rows.length : 25}
            rowsPerPageOptions={false}
            onPageChange={(param) => {
              setStored({ ...stored, page: param.page });
            }}
            disableSelectionOnClick
            disableColumnMenu
            onSortModelChange={(param) => {
              setStored({ ...stored, sortModel: param.sortModel });
            }}
            sortModel={sortModel}
            onFilterModelChange={(param) => {
              setStored({ ...stored, page: 0, filterModel: param.filterModel });
            }}
            filterModel={filterModel}
            localeText={localeText}
          />
        </ErrorBoundary>
      </div>
      <small className={styles.disclaimer}>{disclaimerElements}</small>
    </div>
  );
};

export default TableContainer;
