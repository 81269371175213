import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';

import useToggle from 'hooks/useToggle';
import FormModal from 'components/FormModal/FormModal';
import UserFormContent from 'pages/users/UserFormContent';
import { createUser } from 'api/users';
import { scrollToModalTop } from 'utils/materialUiUtils';

const AddUser = () => {
  const { t, ready } = useTranslation(['users']);
  const baseName = 'users:';
  const translations = ready
    ? {
        header: t(`${baseName}add-user`),
        submitButtonText: t('translation:save'),
        passwordRules: t(`${baseName}password-rules`),
        confirmationHeader: t(`${baseName}modal:confirmationAddHeading`),
        confirmationBody: t(`${baseName}modal:confirmationAddBody`),
      }
    : '';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    value: errorVisible,
    toggle: toggleErrorVisible,
    toggleExactValue: setErrorVisible,
  } = useToggle(false);
  const [error, setError] = useState(null);

  const { register, handleSubmit, control, errors, watch } = useForm();

  const redirect = () => history.push('/users');

  if (!ready) return null;

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorVisible(false);
    try {
      await createUser(data);
      setErrorVisible(false);
      setLoading(false);
      mutate('/users');
      redirect();
    } catch (error) {
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  return (
    <FormModal
      error={error}
      errorVisible={errorVisible}
      toggleErrorVisible={toggleErrorVisible}
      handleSubmit={handleSubmit(onSubmit)}
      loading={loading}
      closeDialog={redirect}
      header={translations?.header}
      submitButtonText={translations?.submitButtonText}
      hasConfirmation={true}
      confirmationHeader={translations?.confirmationHeader}
      confirmationBody={translations?.confirmationBody}
    >
      <UserFormContent
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        translations={translations}
      />
    </FormModal>
  );
};

export default AddUser;
