import TranslationText from 'components/TranslationText/TranslationText';
import BooleanText from 'components/BooleanText/BooleanText';
import TableOfferActions from 'components/TableOfferActions/TableOfferActions';
import TableOfferStatus from 'components/TableOfferStatus/TableOfferStatus';
import TableCouponsActions from 'components/TableCouponsActions/TableCouponsActions';
import TableUserActions from 'components/TableUserActions/TableUserActions';
import TableParticipantsActions from 'components/TableParticipantsActions/TableParticipantsActions';
import TableApprovalActions from 'components/TableApprovalActions/TableApprovalActions';

import {
  updateCampaignStatus,
  updateUserStatus,
  updateCouponStatus,
} from 'api/approvals';
import { sortDate } from 'utils/tablesUtils';

const tables = {
  offers: {
    campaignId: {
      sortable: true,
      filterable: true,
      width: 56,
    },
    corporateName: {
      sortable: true,
      filterable: true,
      width: 138,
      renderCell: (params) => params.value || '-',
    },
    corporateId: {
      sortable: true,
      filterable: true,
      width: 138,
      renderCell: (params) => params.value || '-',
    },
    couponsType: {
      sortable: true,
      filterable: true,
      width: 160,
      renderCell: (params) =>
        params.value === 'POINTS_COLLECTION'
          ? 'Απόδοση πόντων'
          : 'Eξαργύρωση πόντων',
    },
    offerId: {
      sortable: true,
      filterable: true,
      width: 80,
    },
    name: {
      sortable: true,
      filterable: true,
      width: 152,
    },
    beginDate: {
      sortable: true,
      sortComparator: (a, b) => sortDate(a, b),
      width: 138,
    },
    endDate: {
      sortable: true,
      sortComparator: (a, b) => sortDate(a, b),
      width: 138,
    },
    totalCoupons: {
      sortable: true,
      width: 92,
    },
    couponsEndDate: {
      sortable: true,
      sortComparator: (a, b) => sortDate(a, b),
      width: 120,
    },
    participations: {
      sortable: true,
      width: 92,
    },
    redeemedCoupons: {
      sortable: true,
      width: 92,
    },
    actions: {
      renderCell: (params) => (
        <TableOfferActions
          participations={params.row.participations}
          campaignId={params.row.campaignId}
          isEditVisible={params.row.isEditVisible}
        />
      ),
      width: 160,
    },
    status: {
      renderCell: (params) => (
        <TableOfferStatus
          approval={params.row.approval}
          enabled={params.row.enabled}
          campaignId={params.row.campaignId}
          name={params.row.name}
        />
      ),
      width: 160,
    },
  },
  users: {
    userKey: {
      width: 56,
      sortable: true,
    },
    fullName: {
      sortable: true,
      filterable: true,
      width: 176,
    },
    email: {
      sortable: true,
      filterable: true,
      width: 320,
    },
    role: {
      renderCell: (params) =>
        params?.value ? (
          <TranslationText
            translationKey={`users:roles.${params.value}`}
            translationFiles={['translation']}
          />
        ) : (
          '-'
        ),
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.role === 'super_admin') {
          return -1;
        } else if (param2.row.role === 'super_admin') {
          return 1;
        }
        if (param1.row.role === 'user') {
          return 1;
        } else if (param2.row.role === 'user') {
          return -1;
        }
        if (param1.row.role === 'manager' && param2.row.role === 'admin') {
          return 1;
        } else if (
          param1.row.role === 'admin' &&
          param2.row.role === 'manager'
        ) {
          return -1;
        }
        return 0;
      },
      sortable: true,
      width: 176,
    },
    corporateName: {
      sortable: true,
      filterable: true,
      width: 138,
    },
    registeredBy: {
      width: 176,
      sortable: true,
    },
    status: {
      renderCell: (params) =>
        params?.value ? (
          <>
            <TranslationText
              translationKey={`users:status.${params.value}`}
              translationFiles={['users']}
            />
            {params.row.icon}
          </>
        ) : (
          '-'
        ),
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.status === 'inactive') {
          return -1;
        } else if (param2.row.status === 'inactive') {
          return 1;
        }
        if (
          param1.row.status === 'delete' &&
          param2.row.status !== ('inactive' || 'delete')
        ) {
          return -1;
        } else if (
          param2.row.status === 'inactive' &&
          param1.row.status !== ('inactive' || 'delete')
        ) {
          return 1;
        }
        if (
          param1.row.status === 'create' &&
          param2.row.status !== ('active' || 'create')
        ) {
          return -1;
        } else if (
          param2.row.status === 'inactive' &&
          param1.row.status !== ('inactive' || 'create')
        ) {
          return 1;
        }
        if (param1.row.status === 'active') {
          return 1;
        } else if (param2.row.status === 'active') {
          return -1;
        }
        return 0;
      },
      sortable: true,
      width: 208,
    },
    actions: {
      renderCell: (params) => (
        <TableUserActions
          _id={params.row._id}
          fullName={params.row.fullName}
          approval={params.row.approval}
          enabled={params.row.enabled}
        />
      ),
      width: 112,
    },
  },
  offersApprovals: {
    campaignId: {
      sortable: true,
      filterable: true,
      width: 56,
    },
    corporateName: {
      sortable: true,
      filterable: true,
      width: 144,
    },
    corporateId: {
      sortable: true,
      filterable: true,
      width: 128,
    },
    offerId: {
      sortable: true,
      filterable: true,
      width: 96,
    },
    campaignName: {
      filterable: true,
      hide: true,
    },
    details: {
      renderCell: (params) => (
        <TableOfferActions
          isEditVisible={false}
          isParticipantsVisible={false}
          campaignId={params.row.campaignId}
          isApprovalsTable={true}
        />
      ),
      width: 128,
    },
    approvalType: {
      renderCell: (params) =>
        params?.row?.action ? (
          <TranslationText
            translationKey={`approvals:types.${params.row.action}`}
            translationFiles={['approvals']}
          />
        ) : (
          '-'
        ),
      width: 160,
    },
    actions: {
      valueGetter: (params) => `${params.row.status}`,
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.status === 'pending') {
          return -1;
        } else if (
          param1.row.status === 'accepted' &&
          param2.row.status === 'rejected'
        ) {
          return -1;
        } else if (
          param1.row.status === 'rejected' &&
          param2.row.status === 'accepted'
        ) {
          return 1;
        } else {
          return 1;
        }
      },
      renderCell: (params) => (
        <TableApprovalActions
          _id={params.row._id}
          status={params.row.status}
          setUpdate={updateCampaignStatus}
          mutateList={'/approvals/campaigns'}
        />
      ),
      sortable: true,
      width: 272,
    },
    updatedAt: {
      width: 182,
    },
    actionCompletedByFullName: {
      width: 182,
    },
  },
  coupons: {
    campaignId: {
      sortable: true,
      width: 56,
    },
    offerId: {
      filterable: true,
      sortable: true,
      width: 80,
    },
    corporateName: {
      sortable: true,
      filterable: true,
      width: 138,
      renderCell: (params) => params?.value || '-',
    },
    name: {
      sortable: true,
      filterable: true,
      width: 172,
    },
    endDate: {
      sortable: true,
      sortComparator: (a, b) => sortDate(a, b),
      width: 138,
    },
    total: {
      sortable: true,
      width: 138,
    },
    notRedeemed: {
      sortable: true,
      width: 152,
    },
    shared: {
      sortable: true,
      width: 138,
    },
    redeemed: {
      sortable: true,
      width: 162,
    },
    actions: {
      renderCell: (params) => <TableCouponsActions params={params} />,
      width: 92,
    },
  },
  couponsApprovals: {
    campaignId: {
      sortable: true,
      width: 56,
    },
    offerId: {
      sortable: true,
      filterable: true,
      width: 96,
    },
    alphaUserId: {
      sortable: true,
      filterable: true,
      width: 160,
    },
    code: {
      sortable: true,
      filterable: true,
      width: 160,
    },
    details: {
      renderCell: (params) => (
        <TableOfferActions
          isEditVisible={false}
          isParticipantsVisible={false}
          campaignId={params.row.campaignId}
          isApprovalsTable={true}
        />
      ),
      width: 128,
    },
    approvalType: {
      renderCell: (params) =>
        params?.row?.action ? (
          <TranslationText
            translationKey={`approvals:types.${params.row.action}`}
            translationFiles={['approvals']}
          />
        ) : (
          '-'
        ),
      width: 160,
    },
    actions: {
      valueGetter: (params) => `${params.row.status}`,
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.status === 'pending') {
          return -1;
        } else if (
          param1.row.status === 'accepted' &&
          param2.row.status === 'rejected'
        ) {
          return -1;
        } else if (
          param1.row.status === 'rejected' &&
          param2.row.status === 'accepted'
        ) {
          return 1;
        } else {
          return 1;
        }
      },
      renderCell: (params) => (
        <TableApprovalActions
          _id={params.row._id}
          status={params.row.status}
          setUpdate={updateCouponStatus}
          mutateList={'/approvals/coupons'}
        />
      ),
      sortable: true,
      width: 272,
    },
    updatedAt: {
      width: 182,
    },
    actionCompletedByFullName: {
      sortable: true,
      width: 182,
    },
  },
  participants: {
    participantKey: {
      sortable: true,
      width: 56,
    },
    alphaUserId: {
      sortable: true,
      filterable: true,
      width: 160,
    },
    couponCode: {
      filterable: true,
    },
    shared: {
      renderCell: (params) => <BooleanText boolean={params.value} />,
      width: 138,
    },
    shared_at: {
      sortable: true,
      sortComparator: (a, b) => sortDate(a, b),
      width: 138,
    },
    actions: {
      renderCell: (params) => (
        <TableParticipantsActions
          _id={params.row._id}
          campaignId={params.row.campaignId}
          couponCode={params.row.couponCode}
          redeemed={params.row.redeemed}
          canceled={params.row.canceled}
          approval={params.row.approval}
        />
      ),
      width: 320,
    },
    redeemed: {
      renderCell: (params) => <BooleanText boolean={params.value} />,
      width: 176,
    },
    redeemed_at: {
      width: 154,
    },
    status: {
      renderCell: (params) =>
        params?.value ? (
          <TranslationText
            translationKey={`participants:status.${params.value}`}
            translationFiles={['participants']}
          />
        ) : (
          '-'
        ),
      width: 138,
    },
  },
  usersApprovals: {
    userApprovalKey: {
      sortable: true,
      width: 56,
    },
    userFullName: {
      filterable: true,
      sortable: true,
      width: 144,
    },
    email: {
      filterable: true,
      sortable: true,
      width: 160,
    },
    role: {
      width: 124,
      sortable: true,
      renderCell: (params) =>
        params?.row?.role ? (
          <TranslationText
            translationKey={`users:roles.${params.row.role}`}
            translationFiles={['users']}
          />
        ) : (
          '-'
        ),
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.role === 'super_admin') {
          return -1;
        } else if (param2.row.role === 'super_admin') {
          return 1;
        }
        if (param1.row.role === 'manager' && param2.row.role === 'admin') {
          return 1;
        } else if (
          param1.row.role === 'admin' &&
          param2.row.role === 'manager'
        ) {
          return -1;
        } else if (param1.row.role === undefined) {
          return 1;
        } else if (param2.row.role === undefined) {
          return -1;
        }
        if (param1.row.role === 'user') {
          return 1;
        } else if (param2.row.role === 'user') {
          return -1;
        }
        return 0;
      },
    },
    corporateName: {
      sortable: true,
      filterable: true,
      width: 138,
    },
    createdByFullName: {
      sortable: true,
      width: 144,
    },
    approvalType: {
      renderCell: (params) =>
        params?.row?.action ? (
          <TranslationText
            translationKey={`approvals:types.${params.row.action}`}
            translationFiles={['approvals']}
          />
        ) : (
          '-'
        ),
      width: 144,
    },
    actions: {
      valueGetter: (params) => `${params.row.status}`,
      sortComparator: (v1, v2, param1, param2) => {
        if (param1.row.status === 'pending') {
          return -1;
        } else if (
          param1.row.status === 'accepted' &&
          param2.row.status === 'rejected'
        ) {
          return -1;
        } else if (
          param1.row.status === 'rejected' &&
          param2.row.status === 'accepted'
        ) {
          return 1;
        } else {
          return 1;
        }
      },
      renderCell: (params) => (
        <TableApprovalActions
          _id={params.row._id}
          status={params.row.status}
          setUpdate={updateUserStatus}
          mutateList={'/approvals/users'}
        />
      ),
      sortable: true,
      width: 272,
    },
    updatedAt: {
      sortable: true,
      width: 144,
    },
    actionCompletedByFullName: {
      sortable: true,
      width: 144,
    },
  },
  partners: {
    corporateKey: {
      width: 56,
      sortable: true,
    },
    name: {
      sortable: true,
      filterable: true,
      width: 320,
    },
    corporateId: {
      sortable: true,
      filterable: true,
      width: 138,
    },
  },
};

export default tables;
