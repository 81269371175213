import storage from './storage';

const TOKEN_KEY = 'token';

const getToken = () => {
  return storage.getItem({ key: TOKEN_KEY });
};

const setToken = ({ token }) => {
  return storage.setItem({ key: TOKEN_KEY, value: token, expiresInDays: 1 });
};

const removeToken = () => {
  return storage.removeItem({ key: TOKEN_KEY });
};

const clearAuthTokens = () => {
  removeToken();
};

const authStorage = {
  getToken,
  setToken,
  removeToken,
  clearAuthTokens,
};
export default authStorage;
