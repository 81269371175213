import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Inputs/TextInput';
import { TextInputCharacterLimit } from 'components/Inputs/TextInputCharacterLimit';
import { DateRangePicker } from 'components/Inputs/DateRangePicker';
import { NumberInput } from 'components/Inputs/NumberInput';
import PrefillableInput from 'components/PrefillableInput/PrefillableInput';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import SwitchWithLabel from '../../components/SwitchWithLabel/SwitchWithLabel';
import usePartners from '../../data/usePartners';

import styles from './OfferFormContent.module.scss';

const couponsTypes = {
  collection: 'POINTS_COLLECTION',
  default: 'DEFAULT',
};

const OfferFormContent = ({
  register,
  errors,
  control,
  defaultValues,
  active = false,
  request,
  loading,
  setValue,
  watch,
}) => {
  const { t, ready } = useTranslation(['offers', 'translation', 'datagrid']);
  const baseName = 'offers:';
  const translations = ready
    ? {
        name: t(`${baseName}offer-name`),
        name_en: t(`${baseName}offer-name-en`),
        description: t(`${baseName}offer-description`),
        description_en: t(`${baseName}offer-description-en`),
        offerId: t(`${baseName}offer-id`),
        welcomeId: t(`${baseName}welcome-id`),
        corporateName: t(`${baseName}coworker-name`),
        corporateId: t(`${baseName}corporate-id`),
        alphaBonusPoints: t(`${baseName}alpha-bonus-points`),
        startDate: t(`${baseName}start-date`),
        endDate: t(`${baseName}end-date`),
        totalCoupons: t(`${baseName}coupon-number`),
        couponsNotificationLimit: t(`${baseName}coupon-limit`),
        dates: t(`datagrid:datesDisclaimer`, { stars: '*' }),
        fewOffers: t(`datagrid:fewOffersDisclaimer`, { stars: '**' }),
        optional: t('translation:optional'),
      }
    : {};

  const setCouponsType = () => {
    setValue(
      'couponsType',
      couponsTypeValue === couponsTypes.collection
        ? couponsTypes.default
        : couponsTypes.collection
    );
  };

  const couponsTypeValue = watch('couponsType');
  const offerIdValue = watch('corporateId');
  const { data: partners } = usePartners();

  const corporateName = partners?.corporates?.find(
    (corporate) => corporate?.corporateId === offerIdValue
  )?.name;

  useEffect(() => {
    if (couponsTypeValue === couponsTypes.collection) {
      setValue('corporateName', corporateName);
    }
  }, [couponsTypeValue, corporateName, setValue]);

  return (
    <>
      <SwitchWithLabel
        label={'Αυτή η προσφορά αποδίδει Alpha Bonus Points?'}
        handleClick={setCouponsType}
        checked={couponsTypeValue === couponsTypes.collection}
        disabled={active}
        labelPlacement={'start'}
        className={styles.switchContainer}
        switchClassName={styles.switch}
      />

      <div className={styles.inline}>
        <TextInput
          className={styles.inlineInput}
          register={register}
          errors={errors}
          name="offerId"
          label={translations?.offerId}
          disabled={active}
        />
        {couponsTypeValue !== couponsTypes.collection && (
          <PrimaryButtonLoader
            onClick={request}
            disabled={active || loading}
            loading={loading}
          >
            {translations.welcomeId}
          </PrimaryButtonLoader>
        )}
      </div>
      <PrefillableInput
        register={register}
        errors={errors}
        name="name"
        label={translations?.name}
        control={control}
        inputComponent={{ component: TextInputCharacterLimit }}
        maxLength={40}
      />
      <PrefillableInput
        register={register}
        errors={errors}
        name="name_en"
        label={translations?.name_en}
        control={control}
        inputComponent={{ component: TextInputCharacterLimit }}
        maxLength={40}
      />
      <PrefillableInput
        register={register}
        errors={errors}
        defaultValue={defaultValues?.description}
        name="description"
        control={control}
        label={translations?.description}
        inputComponent={{ component: TextInputCharacterLimit }}
        minLength={3}
      />
      <PrefillableInput
        register={register}
        errors={errors}
        defaultValue={defaultValues?.description}
        name="description_en"
        control={control}
        label={translations?.description_en}
        inputComponent={{ component: TextInputCharacterLimit }}
        minLength={3}
      />
      {couponsTypeValue === couponsTypes.collection && (
        <PrefillableInput
          register={register}
          errors={errors}
          defaultValue={defaultValues?.alphaBonusPoints}
          name="alphaBonusPoints"
          control={control}
          label={translations?.alphaBonusPoints}
          inputComponent={{ component: NumberInput }}
        />
      )}
      <PrefillableInput
        register={register}
        errors={errors}
        name="corporateName"
        label={translations?.corporateName}
        disabled={active}
        control={control}
        inputComponent={{ component: TextInput }}
        required={true}
      />
      <PrefillableInput
        register={register}
        errors={errors}
        name="corporateId"
        label={translations?.corporateId}
        disabled={active}
        control={control}
        inputComponent={{ component: TextInput }}
        required={true}
      />
      <DateRangePicker
        control={control}
        startDatePlaceholderText={translations?.startDate}
        endDatePlaceholderText={translations?.endDate}
        errors={errors}
        name="DateRangePicker"
        defaultValue={defaultValues?.DateRangePicker}
      />
      <small>{translations?.dates}</small>
      <NumberInput
        register={register}
        errors={errors}
        name="totalCoupons"
        label={translations?.totalCoupons}
        disabled={active}
      />
      <PrefillableInput
        register={register}
        errors={errors}
        name="couponsNotificationLimit"
        label={translations?.couponsNotificationLimit}
        disabled={active}
        isRequired={false}
        control={control}
        inputComponent={{ component: NumberInput }}
      />
      <small>{translations?.fewOffers}</small>
    </>
  );
};

export default OfferFormContent;
