import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';
import {
  validateOrShowError,
  validateCouponPrefix,
} from '../../utils/validators';

const PrefixInput = ({
  register,
  errors,
  label,
  name = 'prefix',
  required = true,
  digitsLength = 4,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  return (
    <StandardInput
      inputRef={register({
        required: {
          value: required,
          message: ready ? t(`${baseName}.required`) : '',
        },
        maxLength: {
          value: digitsLength,
          message: ready
            ? t(`${baseName}.exactLengthNumber`, { number: digitsLength })
            : '',
        },
        minLength: {
          value: digitsLength,
          message: ready
            ? t(`${baseName}.exactLengthNumber`, { number: digitsLength })
            : '',
        },
        validate: (value) =>
          validateOrShowError(
            value,
            validateCouponPrefix,
            ready ? t(`${baseName}.confirm-prefix-rules`) : ''
          ),
      })}
      label={label}
      name={name}
      type="text"
      errorMessage={errors[name]?.message}
      error={!!errors[name]}
      required={required}
      {...rest}
    />
  );
};

export { PrefixInput };
