import Offers from 'pages/offers/Offers';
import Users from 'pages/users/Users';
import Coupons from 'pages/coupons/Coupons';
import Ecoupons from 'pages/ecoupons/Ecoupons';
import ApprovalsOffers from 'pages/approvals/ApprovalsOffers';
import ApprovalsUsers from 'pages/approvals/ApprovalsUsers';
import ApprovalsCoupons from 'pages/approvals/ApprovalsCoupons';
import Profile from 'pages/profile/Profile';
import OfferParticipants from 'pages/offers/OfferParticipants';
import AddOffer from 'pages/offers/AddOffer';
import AddOfferSecondStep from 'pages/offers/AddOfferSecondStep';
import EditOfferWrapper from 'pages/offers/EditOfferWrapper';
import ViewOfferModal from 'pages/offers/ViewOfferModal';
import AddUser from 'pages/users/AddUser';
import EditUserWrapper from 'pages/users/EditUserWrapper';
import Partners from 'pages/partners/Partners';

const offersSubRoutes = [
  { path: '/offers/add', component: <AddOffer />, exact: true },
  { path: '/offers/add/:campaignId', component: <AddOfferSecondStep /> },
  { path: '/offers/:campaignId/edit/', component: <EditOfferWrapper /> },
  {
    path: '/offers/:campaignId/details/',
    component: <ViewOfferModal redirectUrl={'/offers'} />,
  },
  { redirectTo: '/offers' },
];

const offersSubRoutesRoles = {
  super_admin: [...offersSubRoutes],
  admin: [...offersSubRoutes],
  manager: [...offersSubRoutes],
  user: [],
};

const offers = [
  { path: '/offers/:campaignId/participants/', component: OfferParticipants },
  { path: '/offers', component: Offers, subRoutes: offersSubRoutesRoles },
];

const usersSubRoutes = [{ redirectTo: '/users' }];
const super_adminSubRoutes = [
  { path: '/users/add', component: <AddUser />, exact: true },
  { path: '/users/:userId/edit/', component: <EditUserWrapper /> },
];
const usersSubRoutesRoles = {
  super_admin: [...super_adminSubRoutes, ...usersSubRoutes],
  admin: [...usersSubRoutes],
  manager: [...usersSubRoutes],
  user: [],
};
const users = [
  { path: '/users', component: Users, subRoutes: usersSubRoutesRoles },
];

const coupons = [{ path: '/coupons', component: Coupons }];

const approvalsOffersSubRoutes = [
  {
    path: '/approvals/offers/:campaignId/details',
    component: (
      <ViewOfferModal
        redirectUrl="/approvals/offers"
        showPendingFields={true}
        isFromApprovals={true}
      />
    ),
    exact: true,
  },
  { redirectTo: '/approvals/offers' },
];
const approvalsOffersSubRoles = {
  super_admin: [...approvalsOffersSubRoutes],
  admin: [...approvalsOffersSubRoutes],
  manager: [...approvalsOffersSubRoutes],
  user: [],
};
const approvalsCouponsSubRoutes = [
  {
    path: '/approvals/coupons/:campaignId/details',
    component: (
      <ViewOfferModal
        redirectUrl="/approvals/coupons"
        showPendingFields={false}
        isFromApprovals={true}
      />
    ),
    exact: true,
  },
  { redirectTo: '/approvals/coupons' },
];
const approvalsCouponsSubRoles = {
  super_admin: [...approvalsCouponsSubRoutes],
  admin: [...approvalsCouponsSubRoutes],
  manager: [...approvalsCouponsSubRoutes],
  user: [],
};
const approvals = [
  { path: '/approvals/', redirectTo: '/approvals/offers', exact: true },
  {
    path: '/approvals/offers',
    component: ApprovalsOffers,
    subRoutes: approvalsOffersSubRoles,
  },
  { path: '/approvals/users', component: ApprovalsUsers },
  {
    path: '/approvals/coupons',
    component: ApprovalsCoupons,
    subRoutes: approvalsCouponsSubRoles,
  },
];

const ecoupons = [{ path: '/ecoupons', component: Ecoupons }];

const partners = [{ path: '/partners', component: Partners }];

const profile = [{ path: '/profile', component: Profile }];

const roles = {
  super_admin: {
    label: 'Administrator',
    defaultUrl: '/offers',
    routes: [
      ...offers,
      ...users,
      ...coupons,
      ...approvals,
      ...partners,
      ...profile,
    ],
  },
  admin: {
    label: 'Manager/Approver',
    defaultUrl: '/offers',
    routes: [
      ...offers,
      ...users,
      ...coupons,
      ...approvals,
      ...partners,
      ...profile,
    ],
  },
  manager: {
    label: 'Manager',
    defaultUrl: '/offers',
    routes: [...offers, ...users, ...coupons, ...partners, ...profile],
  },
  user: {
    label: 'Merchant',
    defaultUrl: '/ecoupons',
    routes: [...ecoupons, ...profile],
  },
};

export default roles;
