import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';

import useToggle from 'hooks/useToggle';
import { formUserObject } from 'utils/users/usersUtils';
import { updateUser } from 'api/users';
import FormModal from 'components/FormModal/FormModal';
import UserFormContent from 'pages/users/UserFormContent';
import { scrollToModalTop } from 'utils/materialUiUtils';

const EditUser = ({
  userObject,
  userLoading,
  userError,
  getUser,
  redirect,
}) => {
  const { t, ready } = useTranslation(['users', 'translation']);
  const baseName = 'users:';
  const translations = ready
    ? {
        editHeader: t(`${baseName}edit-user`),
        unlockHeader: t(`${baseName}unlock-user`),
        editButtonText: t('translation:edit'),
        unlockButtonText: t('translation:unlock'),
        confirmationEditHeader: t('users:modal:confirmationEditHeading'),
        confirmationEditBody: t('users:modal:confirmationEditBody'),
        confirmationUnlockHeader: t('users:modal:confirmationUnlockHeading'),
        confirmationUnlockBody: t('users:modal:confirmationUnlockBody'),
      }
    : {};
  const [loading, setLoading] = useState(false);
  const {
    value: errorVisible,
    toggle: toggleErrorVisible,
    toggleExactValue: setErrorVisible,
  } = useToggle(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: userObject,
    shouldUnregister: false,
  });

  if (!ready) return null;

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorVisible(false);
    try {
      const finalData = { ...formUserObject(data), enabled: true };
      if (data.corporateId) {
        finalData.corporateId = data.corporateId;
      }
      await updateUser(finalData);
      setErrorVisible(false);
      setLoading(false);
      mutate('/users');
      redirect();
    } catch (error) {
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  return (
    <FormModal
      error={error}
      errorVisible={errorVisible}
      toggleErrorVisible={toggleErrorVisible}
      disableSubmitButton={
        userObject?.enabled ? Object.keys(dirtyFields).length === 0 : false
      }
      handleSubmit={handleSubmit(onSubmit)}
      loading={loading}
      closeDialog={redirect}
      getRequestLoading={userLoading}
      getRequestError={userError}
      retryGetRequest={getUser}
      header={
        userObject?.enabled
          ? translations?.editHeader
          : translations?.unlockHeader
      }
      submitButtonText={
        userObject?.enabled
          ? translations?.editButtonText
          : translations?.unlockButtonText
      }
      hasConfirmation={true}
      confirmationHeader={
        userObject?.enabled
          ? translations?.confirmationEditHeader
          : translations?.confirmationUnlockHeader
      }
      confirmationBody={
        userObject?.enabled
          ? translations?.confirmationEditBody
          : translations?.confirmationUnlockBody
      }
    >
      <UserFormContent
        register={register}
        errors={errors}
        control={control}
        watch={watch}
        editUser={true}
        translations={translations}
      />
    </FormModal>
  );
};

export default EditUser;
