import React from 'react';

import InnerLoader from 'components/Buttons/InnerLoader';
import DialogOverlay from 'components/DialogOverlay/DialogOverlay';

import { colors } from 'utils/materialTheme';
import styles from './GeneralMessageDialog.module.scss';

const GeneralMessageDialog = ({
  open,
  closeDialog,
  children,
  dialogActions,
  dialogHeader,
  showCloseButton = true,
  disableBackdropClick = true,
  isLoading = false,
}) => {
  const darkBlue = colors.darkBlue;
  const size = 64;

  return (
    <DialogOverlay
      open={open}
      closeDialog={closeDialog}
      dialogActions={dialogActions}
      dialogHeader={dialogHeader}
      dialogHeaderClassname={styles.title}
      dialogClassname={styles.dialog}
      dialogContentClassname={styles.content}
      dialogActionsClassname={styles.actions}
      disableBackdropClick={disableBackdropClick}
      showCloseButton={showCloseButton}
    >
      {isLoading ? (
        <InnerLoader loading={true} size={size} color={darkBlue} />
      ) : (
        children
      )}
    </DialogOverlay>
  );
};

export default GeneralMessageDialog;
