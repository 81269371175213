import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { PrimaryButton } from 'components/Buttons/Buttons';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useDialog from 'hooks/useDialog';

import styles from '../../styles/components/tableCells.module.scss';

const TableApprovalActions = ({ _id, status, setUpdate, mutateList }) => {
  const [requestedStatus, setRequestedStatus] = useState(status);

  const { t, ready } = useTranslation(['approvals']);

  const translation = ready
    ? {
        rejectLabel: t(`approvals:actions:reject`),
        approveLabel: t(`approvals:actions:approve`),
        rejectedLabel: t(`approvals:actions:rejected`),
        approvedLabel: t(`approvals:actions:approved`),
        modal: t(`approvals:modal`, { returnObjects: true }),
      }
    : '';

  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestApprovalStatusChange = async () => {
    startLoading();
    const data = { _id, status: requestedStatus };
    try {
      await setUpdate(data);
      await mutate(mutateList);
      closeDialog();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage || '');
    }
  };

  const handleClick = (status) => {
    setRequestedStatus(status);
    openDialog();
  };

  return (
    <>
      <div className={styles.container}>
        {status === 'pending' ? (
          <div>
            <PrimaryButton
              className={styles.button}
              onClick={() => handleClick('rejected')}
            >
              {translation?.rejectLabel}
            </PrimaryButton>
            <PrimaryButton
              className={styles.button}
              onClick={() => handleClick('accepted')}
            >
              {translation?.approveLabel}
            </PrimaryButton>
          </div>
        ) : status === 'rejected' ? (
          translation?.rejectedLabel
        ) : status === 'accepted' ? (
          translation?.approvedLabel
        ) : (
          ''
        )}
      </div>
      <ConfirmationDialog
        open={isOpen}
        closeDialog={closeDialog}
        dialogHeader={
          requestedStatus === 'accepted'
            ? translation?.modal?.acceptHeading
            : translation?.modal?.rejectHeading
        }
        isLoading={isLoading}
        request={requestApprovalStatusChange}
        error={hasError}
        errorMessage={dialogErrorMessage}
      >
        {requestedStatus === 'accepted'
          ? translation?.modal?.acceptBody
          : translation?.modal?.rejectBody}
      </ConfirmationDialog>
    </>
  );
};
export default TableApprovalActions;
