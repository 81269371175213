import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AlphaBonusLogo } from 'assets/icons/ΑlphaBonusLogo.svg';

import styles from './LoginWrapper.module.scss';

const logoWidth = '12.5rem';

const LoginWrapper = ({
  children,
  figureContainerClass = '',
  headerContainerClass = '',
  sectionContainerClass = '',
  containerClass = '',
}) => {
  const { t, ready } = useTranslation(['translation']);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${containerClass}`}>
        <section className={`${styles.section} ${sectionContainerClass}`}>
          <figure className={`${figureContainerClass}`}>
            <AlphaBonusLogo width={logoWidth} />
          </figure>
          <h1 className={`${styles.header} ${headerContainerClass}`}>
            {ready ? t('translation:app-title') : ''}
          </h1>
        </section>

        {children}
      </div>
    </div>
  );
};

export default LoginWrapper;
