import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';
import { formatUnixDate } from 'utils/dateUtils';

export default function useParticipants(campaignId) {
  const { data, error } = useSWR(
    `campaigns/participations?campaignId=${campaignId}`,
    fetcher
  );

  const loading = !data && !error;

  const rows = data ? getTableRows(data.participations) : [];

  rows.map((row, index) => {
    row.participantKey = index + 1;
    row.shared_at = formatUnixDate(row.shared_at, 'dd/MM/yyyy') || '-';
    row.redeemed_at = formatUnixDate(row.redeemed_at, 'dd/MM/yyyy') || '-';
    row.status = row.canceled
      ? 'canceled'
      : row.redeemed
      ? 'redeemed'
      : row.shared
      ? 'shared'
      : '';
    return row;
  });

  return {
    loading,
    error,
    rows,
  };
}
