import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import SwitchWithLabel from 'components/SwitchWithLabel/SwitchWithLabel';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { changeCampaignStatus } from 'api/campaigns';
import useDialog from 'hooks/useDialog';

import styles from '../../styles/components/tableCells.module.scss';

const TableOfferStatus = ({
  enabled: isEnabled,
  approval,
  campaignId,
  name,
}) => {
  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestStatusChange = async () => {
    startLoading();
    const enabled = !isEnabled;
    const data = { campaignId, enabled };
    try {
      await changeCampaignStatus(data);
      await mutate('/campaigns/list');
      closeDialog();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage || '');
    }
  };

  const { t, ready } = useTranslation(['offers']);
  const translation = ready
    ? {
        switchLabels: t(`offers:switch`, { returnObjects: true }),
        pending: t(`offers:pending`, { returnObjects: true }),
        modal: t(`offers:modal`, { returnObjects: true }),
      }
    : '';

  const isActivationPending =
    approval?.status === 'pending' && approval?.action === 'enable';
  const isDeactivationPending =
    approval?.status === 'pending' && approval?.action === 'disable';
  const isUpdatePending =
    approval?.status === 'pending' && approval?.action === 'update';

  const pendingMessage = isActivationPending
    ? translation?.pending?.activate
    : isDeactivationPending
    ? translation?.pending?.deactivate
    : isUpdatePending
    ? ' ' + translation?.pending?.edit
    : '';

  const handleClick = () => {
    openDialog();
  };

  return (
    <>
      <div className={styles.container}>
        {pendingMessage ? (
          <div className={styles.pending}>
            {pendingMessage}
            <UpdateIcon />
          </div>
        ) : (
          <SwitchWithLabel
            checked={isEnabled}
            label={
              isEnabled
                ? translation?.switchLabels?.active
                : translation?.switchLabels?.inactive
            }
            handleClick={handleClick}
          />
        )}
      </div>
      <ConfirmationDialog
        open={isOpen}
        closeDialog={closeDialog}
        dialogHeader={
          isEnabled
            ? translation?.modal?.deactivateHeading
            : translation?.modal?.activateHeading
        }
        isLoading={isLoading}
        request={requestStatusChange}
        error={hasError}
        errorMessage={dialogErrorMessage}
      >
        {isEnabled
          ? `${translation?.modal?.deactivateBody} '${name}';`
          : `${translation?.modal?.activateBody} '${name}';`}
      </ConfirmationDialog>
    </>
  );
};

export default TableOfferStatus;
