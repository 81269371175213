import api from './api';
import apiBlob from './apiBlob';

const checkCouponCode = async (code) => {
  const couponObject = await api.get(`/campaigns/coupons/check?code=${code}`);
  return couponObject?.coupon;
};

const cancelCoupon = (data) => {
  return api.put('/campaigns/participation/cancel/coupon', data);
};

const redeemCoupon = (data) => {
  return api.put('/campaigns/coupons/backoffice/redeem', data);
};

const getCouponExcelFile = (campaignId) => {
  return apiBlob.get(`/campaigns/coupons/excel/${campaignId}`, {
    responseType: 'blob',
  });
};

const getCouponsExcelFile = (id) => {
  return apiBlob.get(`/campaigns/couponsinfo/excel`, {
    responseType: 'blob',
  });
};

const getAwardedCouponsExcelFile = (fromDate, toDate) => {
  return apiBlob.get(
    `/campaigns/coupons/redeemed/merchants/excel?fromDate=${fromDate}&toDate=${toDate}`,
    {
      responseType: 'blob',
    }
  );
};

export {
  checkCouponCode,
  cancelCoupon,
  redeemCoupon,
  getCouponExcelFile,
  getCouponsExcelFile,
  getAwardedCouponsExcelFile,
};
