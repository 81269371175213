import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconText from 'components/IconText/IconText';
import MenuButton from 'components/MenuButton/MenuButton';

import styles from './AuthorizedHeaderElements.module.scss';
import AuthorizedHeaderMenuElementList from 'components/AuthorizedHeaderContent/AuthorizedHeaderMenuElementList';

const AuthorizedHeaderMenuElement = ({
  icon,
  content,
  translationText,
  links,
  contentClass = styles.iconTextContentWrapper,
  iconClass = styles.icon,
  buttonClasses = { root: styles.link },
}) => {
  const { t, ready } = useTranslation(['header']);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = (path) => {
    history.push(path);
    setAnchorEl(null);
  };

  return (
    <MenuButton
      buttonClasses={buttonClasses}
      buttonText={
        <IconText
          contentClass={contentClass}
          iconClass={iconClass}
          Icon={icon}
          content={ready && translationText ? t(translationText) : content}
        />
      }
      handleClose={handleClose}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    >
      <AuthorizedHeaderMenuElementList
        links={links}
        ready={ready}
        t={t}
        handleClose={handleClose}
      />
    </MenuButton>
  );
};

export default AuthorizedHeaderMenuElement;
