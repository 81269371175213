import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';

import { NoStylesButton } from 'components/Buttons/Buttons';
import { getCouponExcelFile } from 'api/coupons';
import downloadExcel from 'utils/downloadExcel';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import useDialog from 'hooks/useDialog';
import InnerLoader from 'components/Buttons/InnerLoader';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';

import { colors } from 'utils/materialTheme';
import styles from './TableCouponsActions.module.scss';

const TableCouponsActions = ({ params }) => {
  const darkBlue = colors.darkBlue;
  const { t, ready } = useTranslation(['translation', 'coupons']);
  const [loading, setLoading] = useState(false);

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const handleClose = () => {
    closeDialog();
    setLoading(false);
  };

  const requestExcel = async () => {
    setLoading(true);
    startLoading();
    try {
      const response = await getCouponExcelFile(params?.row?.campaignId);
      downloadExcel(response.data, params?.row?.name ?? 'Coupon');
      handleClose();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(ready ? t('translation:something-wrong') : '');
      }
    }
  };

  return (
    <div className={styles.container}>
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {ready ? t('coupons:modal:errorButton') : ''}
          </PrimaryButtonLoader>
        }
        closeDialog={handleClose}
        dialogHeader={ready ? t('coupons:actionsLabels:download') : ''}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
      <div className={styles.span}>
        <NoStylesButton
          aria-label={ready ? t('coupons:actionsLabels:download') : ''}
          title={ready ? t('coupons:actionsLabels:download') : ''}
          onClick={requestExcel}
        >
          <GetAppIcon />
        </NoStylesButton>
        <InnerLoader
          className={styles.icon}
          loading={loading}
          size={16}
          color={darkBlue}
        />
      </div>
    </div>
  );
};

export default TableCouponsActions;
