import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import moment from 'moment';

import useToggle from 'hooks/useToggle';
import { formCampaignsCreateCouponsObject } from 'utils/offers/offersUtils';
import { createCampaignCoupons, getCouponsStatus } from 'api/campaigns';
import FormModal from 'components/FormModal/FormModal';
import OfferFormContentSecondStep from 'pages/offers/OfferFormContentSecondStep';
import { scrollToModalTop } from 'utils/materialUiUtils';

const scope = 'addOffer';

const AddOfferSecondStep = () => {
  const { t, ready } = useTranslation(['offers', 'translation']);
  const baseName = 'offers:';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    value: errorVisible,
    toggle: toggleErrorVisible,
    toggleExactValue: setErrorVisible,
  } = useToggle(false);
  const [error, setError] = useState(null);
  const { campaignId } = useParams();
  const [selectValue, setSelectValue] = useState('0');
  const { state } = useLocation();

  const redirect = () => history.push('/offers');

  const { register, handleSubmit, control, errors, setValue } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue('DateRangePicker', {
      startDate: moment.unix(state.beginDate),
    });
  }, [setValue, state]);

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorVisible(false);
    try {
      const finalData = formCampaignsCreateCouponsObject({
        ...data,
        campaignId,
      });

      if (!state.isPreMadeOffer) {
        const today = moment().startOf('day').format('X');
        const isBeginDateInPast = finalData.beginDate < today;
        const isEndDateInPast = finalData.endDate < today;
        if (isBeginDateInPast) {
          setErrorVisible(true);
          scrollToModalTop();
          if (isEndDateInPast) {
            setError({ userMessage: t(`offers:bothDatesError`) });
          } else {
            setError({ userMessage: t(`offers:beginDateError`) });
          }
          setLoading(false);
          return;
        }
      }
      if (finalData.endDate < state.endDate) {
        setErrorVisible(true);
        scrollToModalTop();
        setError({
          userMessage: `${t(`offers:couponΕndDateError`)} ${moment
            .unix(state.endDate)
            .format('DD/MM/YYYY')}.`,
        });
        setLoading(false);
        return;
      }
      if (finalData.beginDate < state.beginDate) {
        setErrorVisible(true);
        scrollToModalTop();
        setError({
          userMessage: `${t(`offers:couponBeginDateError`)} ${moment
            .unix(state.beginDate)
            .format('DD/MM/YYYY')}.`,
        });
        setLoading(false);
        return;
      }
      if (selectValue === '0') {
        await createCampaignCoupons(finalData);
        await getCouponsStatus(campaignId);
        setErrorVisible(false);
        setLoading(false);
        mutate('/campaigns/list');
        redirect();
      } else {
        finalData.excelFile = finalData?.excelFile[0];
        await createCampaignCoupons(finalData);
        await getCouponsStatus(campaignId, 0);
        setErrorVisible(false);
        setLoading(false);
        redirect();
      }
    } catch (error) {
      setErrorVisible(true);
      scrollToModalTop();
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  return (
    <FormModal
      error={error}
      errorScope={scope}
      errorVisible={errorVisible}
      toggleErrorVisible={toggleErrorVisible}
      handleSubmit={handleSubmit(onSubmit)}
      loading={loading}
      closeDialog={redirect}
      header={ready ? t(`${baseName}step-2/2`) + t(`${baseName}add-offer`) : ''}
      submitButtonText={ready ? t('translation:save') : ''}
      hasConfirmation={true}
      confirmationHeader={t(`${baseName}modal:confirmationAddHeading`)}
      confirmationBody={t(`${baseName}modal:confirmationAddBody`)}
    >
      <OfferFormContentSecondStep
        register={register}
        errors={errors}
        control={control}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
      />
    </FormModal>
  );
};

export default AddOfferSecondStep;
