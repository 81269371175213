import TableContainer from '@material-ui/core/TableContainer';
import React from 'react';
import Table from '@material-ui/core/Table';

import TableBody from './TableBody';
import { TableCellHeader } from './TableCell';
import TableHead from './TableHead';
import TableRow from './TableRow';

export default function DenseTable({ children, headerTitles }) {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headerTitles.map(({ key, value }) => (
              <TableCellHeader key={key}>{value}</TableCellHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
