import React from 'react';

import AuthorizedContentContainer from 'components/AuthorizedContentContainer/AuthorizedContentContainer';

import styles from './FullWidthTableLayout.module.scss';

const FullWidthTableLayout = ({ ready = true, children }) => {
  return (
    <AuthorizedContentContainer>
      <main className={styles.container}>
        {ready ? children : <p>Loading...</p>}
      </main>
    </AuthorizedContentContainer>
  );
};

export default FullWidthTableLayout;
