import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';
import { PasswordEndAdornment } from 'components/Inputs/PasswordEndAdornment';

const PasswordInput = ({
  register,
  errors,
  name = 'password',
  label,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';
  const [showPassword, setShowPassword] = React.useState(false);

  if (!ready) return null;

  return (
    <>
      <StandardInput
        inputRef={register({
          required: { value: true, message: t(`${baseName}.required`) },
        })}
        label={label}
        name={name}
        type={showPassword ? 'text' : 'password'}
        errorMessage={errors[name]?.message}
        error={!!errors[name]}
        endAdornment={
          <PasswordEndAdornment
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        }
        {...rest}
      />
    </>
  );
};

export { PasswordInput };
