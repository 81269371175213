import React from 'react';
import { NavLink } from 'react-router-dom';

import SidebarElement from 'components/SidebarElement/SidebarElement';

import styles from './SidebarLinkElement.module.scss';

const SidebarLinkElement = ({ target = '/', Icon, content, iconWeight }) => {
  return (
    <NavLink
      activeClassName={styles.selectedLink}
      className={styles.link}
      to={target}
    >
      <SidebarElement Icon={Icon} content={content} iconWeight={iconWeight} />
    </NavLink>
  );
};

export default SidebarLinkElement;
