import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';

const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EmailInput = ({
  register = () => {},
  errors = {},
  label,
  name = 'email',
  required = true,
  autoComplete = 'on',
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  return (
    <>
      <StandardInput
        inputRef={register({
          required: {
            value: required,
            message: ready ? t(`${baseName}.required`) : '',
          },
          pattern: {
            value: emailRegexPattern,
            message: ready ? t(`${baseName}.email`) : '',
          },
        })}
        label={label}
        name={name}
        autoComplete={autoComplete}
        type="email"
        errorMessage={errors[name]?.message}
        error={!!errors[name]}
        {...rest}
      />
    </>
  );
};

export { EmailInput };
