import React from 'react';
import { useTranslation } from 'react-i18next';

import SingleColumnLayout from 'layouts/SingleColumnLayout';
import CheckCouponForm from 'pages/coupons/CheckCouponForm';

import styles from './Ecoupons.module.scss';

const Ecoupons = () => {
  const { t, ready } = useTranslation(['coupons']);
  const cta = ready ? t(`coupons:merchant:cta`) : '';

  return (
    <SingleColumnLayout>
      <div className={styles.container}>
        <h2 className={styles.cta}>{cta}</h2>
        <CheckCouponForm fullPage={true} />
      </div>
    </SingleColumnLayout>
  );
};

export default Ecoupons;
