import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';

import WidgetHeader from 'components/WidgetHeader/WidgetHeader';
import SidebarLinkElement from 'components/SidebarLinkElement/SidebarLinkElement';
import SidebarButtonElement from 'components/SidebarButtonElement/SidebarButtonElement';
import SidebarLinkMenu from 'components/SidebarLinkMenu/SidebarLinkMenu';
import LogoutDialog from 'components/LogoutDialog/LogoutDialog';
import useToggle from 'hooks/useToggle';

const ProfileRightSidebar = () => {
  const { value: isDialogOpen, toggle: toggleDialogState } = useToggle();
  const { t, ready } = useTranslation(['profile']);
  const baseName = 'profile:';
  const match = useRouteMatch().url;

  if (!ready) return null;

  return (
    <>
      <SidebarLinkMenu
        distance="s"
        title={<WidgetHeader title={t(`${baseName}my-info`)} />}
      >
        <SidebarLinkElement
          target={`${match}/personal-details`}
          Icon={<PersonIcon />}
          content={t(`${baseName}personal-info`)}
        />
        <SidebarLinkElement
          target={`${match}/change-password`}
          Icon={<LockIcon />}
          content={t('profile:change-password')}
        />
      </SidebarLinkMenu>
      <SidebarLinkMenu>
        <SidebarButtonElement
          Icon={<ExitToAppIcon />}
          content={t(`${baseName}logout`)}
          onClick={toggleDialogState}
        />
      </SidebarLinkMenu>
      <LogoutDialog
        open={isDialogOpen}
        closeDialog={toggleDialogState}
      ></LogoutDialog>
    </>
  );
};

export default ProfileRightSidebar;
