import useSWR from 'swr';
import { fetcher } from '../api/api';
import { getTableRows } from 'utils/tablesUtils';
import UpdateIcon from '@material-ui/icons/Update';

export default function useUsers() {
  const { data, error } = useSWR('/users', fetcher);

  const loading = !data && !error;

  const rows = data ? getTableRows(data.users) : [];

  rows.map((row, index) => {
    row.corporateName = row?.corporateName || '-';
    row.registeredBy = row?.registeredBy?.fullName || '-';
    row.userKey = index + 1;
    row.status =
      row?.approval?.status === 'pending'
        ? row.approval.action
        : row.enabled
        ? 'active'
        : 'inactive';
    row.icon = row?.approval?.status === 'pending' ? <UpdateIcon /> : null;

    return row;
  });

  return {
    loading,
    error,
    rows,
  };
}
