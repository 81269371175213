import React from 'react';

import AuthorizedContentContainer from '../components/AuthorizedContentContainer/AuthorizedContentContainer';

import styles from './TwoColumnsLayout.module.scss';

const TwoColumnsLayout = ({ main, right }) => {
  return (
    <AuthorizedContentContainer>
      <div className={styles.container}>
        <main className={styles.main}>{main}</main>
        <aside className={styles.right}>{right}</aside>
      </div>
    </AuthorizedContentContainer>
  );
};

export default TwoColumnsLayout;
