import React from 'react';
import { useTranslation } from 'react-i18next';

import StandardSelect from 'components/Selects/StandardSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import SelectFormControl from 'components/Selects/SelectFormControl';
import SelectLabel from 'components/Selects/SelectLabel';

import styles from './CouponsFormSelect.module.scss';

const CouponsFormSelect = ({ label, handleChange, value, options }) => {
  const { t, ready } = useTranslation(['offers', 'translation']);
  const baseName = 'offers:';

  return (
    <SelectFormControl classes={{ root: styles.MuiFormControlRoot }}>
      <SelectLabel> {label}</SelectLabel>
      <StandardSelect onChange={handleChange} value={value}>
        {options &&
          options.map((option) => {
            const { id, value } = option || {};
            return value ? (
              <StandardSelectOption key={id + 'selectOption'} value={id + ''}>
                {ready ? t(`${baseName}${value}`) : ''}
              </StandardSelectOption>
            ) : null;
          })}
      </StandardSelect>
    </SelectFormControl>
  );
};

export default CouponsFormSelect;
