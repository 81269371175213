import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { dateToGreek, dateToUTC } from 'utils/dateUtils';
import InlineDatePicker from 'components/InlineDatePicker';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import downloadExcel from 'utils/downloadExcel';
import { getAwardedCouponsExcelFile } from 'api/coupons';

import styles from './AwardedCouponForm.module.scss';

const AwardedCouponForm = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const { control, errors, handleSubmit } = useForm({
    shouldUnregister: false,
  });
  const { t, ready } = useTranslation(['coupons', 'translation']);

  const from = ready ? t('coupons:awardedReport.fromDate') : '';
  const to = ready ? t('coupons:awardedReport.toDate') : '';
  const label = ready ? t('coupons:awardedReport.label') : '';
  const defaultErrorMessage = ready
    ? t('coupons:awardedReport.defaultError')
    : '';
  const beware = ready ? t('translation:attention') : '';

  const closeErrorDialog = () => {
    setShowError(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const { startDate, endDate } = data?.DateRangePicker || {};

      const { fromDate, toDate } = {
        fromDate: dateToUTC(moment(dateToGreek(startDate)).startOf('day')),
        toDate: dateToUTC(moment(dateToGreek(endDate)).endOf('day')),
      };

      const response = await getAwardedCouponsExcelFile(fromDate, toDate);
      downloadExcel(response.data, 'Awarded Coupons');
    } catch (error) {
      const errorMessage =
        error.response?.data?.error?.userMessage || defaultErrorMessage;
      setError(errorMessage);
      setShowError(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={className}>
        <form noValidate autoComplete="off" className={styles.form}>
          <InlineDatePicker
            control={control}
            errors={errors}
            name="DateRangePicker"
            minDate={moment(2020)}
            maxDate={moment()}
            startDateLabel={from}
            endDateLabel={to}
          />

          <PrimaryButtonLoader
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={isLoading}
            className={styles.button}
          >
            <GetAppIcon className={styles.icon} />
            {label}
          </PrimaryButtonLoader>
        </form>
      </div>
      <ResponseMessageDialog
        open={showError}
        closeDialog={closeErrorDialog}
        dialogHeader={beware}
      >
        {error}
      </ResponseMessageDialog>
    </>
  );
};

export default AwardedCouponForm;
