import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PeopleIcon from '@material-ui/icons/People';
import { useTranslation } from 'react-i18next';

import styles from './TableOfferActions.module.scss';

const TableOfferActions = ({
  isEditVisible = true,
  isDetailsVisible = true,
  isParticipantsVisible = true,
  participations,
  campaignId,
}) => {
  const match = useRouteMatch();
  const { t, ready } = useTranslation(['offers']);
  const actionsLabels = ready
    ? t(`offers:actionsLabels`, { returnObjects: true })
    : '';

  return (
    <div className={styles.container}>
      {isEditVisible ? (
        <Link
          to={`offers/${campaignId}/edit/`}
          className={styles.link}
          aria-label={actionsLabels.edit}
          title={actionsLabels.edit}
        >
          <CreateIcon />
        </Link>
      ) : null}
      {isDetailsVisible ? (
        <Link
          to={`${match.url}/${campaignId}/details/`}
          className={styles.link}
          aria-label={actionsLabels.details}
          title={actionsLabels.details}
        >
          <CallMadeIcon />
        </Link>
      ) : null}
      {participations && isParticipantsVisible ? (
        <Link
          to={`offers/${campaignId}/participants/`}
          className={styles.link}
          aria-label={actionsLabels.participants}
          title={actionsLabels.participants}
        >
          <PeopleIcon />
        </Link>
      ) : null}
    </div>
  );
};

export default TableOfferActions;
