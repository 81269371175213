import React from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import LockIcon from '@material-ui/icons/Lock';
import { Link, useRouteMatch } from 'react-router-dom';

import useModal from 'hooks/useDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { NoStylesButton } from 'components/Buttons/Buttons';
import { deleteUser } from 'api/users';

import styles from './TableUserActions.module.scss';

const TableUserActions = ({ _id, fullName, approval, enabled }) => {
  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    handleError,
    dialogErrorMessage,
  } = useModal(false);

  const { t, ready } = useTranslation(['users']);
  const { url } = useRouteMatch();

  const translation = ready
    ? {
        actionsLabels: t(`users:actionsLabels`, { returnObjects: true }),
        modal: t('users:modal', { returnObjects: true }),
      }
    : '';

  const isPending = approval?.status === 'pending';

  const requestCouponDeletion = async () => {
    startLoading();
    try {
      await deleteUser(_id);
      mutate('/users');
      closeDialog();
    } catch (error) {
      handleError(error.response?.data?.error?.userMessage || '');
    }
  };

  const handleClick = () => {
    openDialog();
  };

  return (
    <div className={styles.container}>
      {isPending ? (
        '-'
      ) : (
        <>
          <NoStylesButton
            aria-label={translation?.actionsLabels?.delete}
            title={translation?.actionsLabels?.delete}
            onClick={handleClick}
          >
            <DeleteIcon />
          </NoStylesButton>
          <Link
            aria-label={
              enabled
                ? translation?.actionsLabels?.edit
                : translation?.actionsLabels?.unlock
            }
            title={
              enabled
                ? translation?.actionsLabels?.edit
                : translation?.actionsLabels?.unlock
            }
            className={styles.link}
            to={{ pathname: `${url}/${_id}/edit` }}
          >
            {enabled ? (
              <CreateIcon role="button" />
            ) : (
              <LockIcon role="button" />
            )}
          </Link>
          <ConfirmationDialog
            open={isOpen}
            closeDialog={closeDialog}
            dialogHeader={translation?.modal?.deleteHeading}
            isLoading={isLoading}
            request={requestCouponDeletion}
            error={hasError}
            errorMessage={dialogErrorMessage}
          >
            {`${translation?.modal?.deleteBody} '${fullName}'`}
          </ConfirmationDialog>
        </>
      )}
    </div>
  );
};

export default TableUserActions;
