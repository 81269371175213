import axios from 'axios';
import { apiConfig, requestSuccess, requestError } from 'utils/apiUtils';

export const fetcher = (url) => apiBlob.get(url).then((res) => res);

const apiBlob = axios.create(apiConfig);

apiBlob.interceptors.request.use(
  (config) => requestSuccess(config),
  (error) => requestError(error)
);

export default apiBlob;
