import React from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './GenericLoader.module.scss';

const GenericLoader = ({
  progressClass,
  size = 64,
  thickness = 5,
  color = '#ffffff',
  variant = 'indeterminate',
  disableShrink = true,
}) => {
  const progressStyles = { color: color };
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <CircularProgress
          className={progressClass ? progressClass : styles.progress}
          style={progressStyles}
          variant={variant}
          disableShrink={disableShrink}
          size={size}
          thickness={thickness}
        />
      </div>
    </div>
  );
};

export default GenericLoader;
