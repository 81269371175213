import React, { useState, useContext, useEffect } from 'react';

import authStorage from 'utils/storage/authStorage';
import { login as loginService, logout as logoutService } from 'api/auth';
import { getUser } from 'api/users';
import api from 'api/api';

const AuthUserContext = React.createContext();

export const AuthUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = authStorage.getToken();
      if (token) {
        try {
          const { user } = await getUser();
          if (user) setUser(user);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const login = async ({ email, password }) => {
    authStorage.clearAuthTokens();
    const {
      data: {
        payload: { users: user },
      },
    } = await loginService({ email, password });
    if (user?.token) {
      authStorage.setToken({ token: user.token });
      if (user) setUser(user);
      return { user };
    }
  };

  const logout = async () => {
    try {
      await logoutService();
    } catch (err) {
      console.error(err);
    }
    authStorage.clearAuthTokens();
    setUser(null);
  };

  return (
    <AuthUserContext.Provider
      value={{ isLoggedIn: !!user, user, login, loading, logout }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};
