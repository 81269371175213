import React from 'react';

import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorIcon.svg';

import styles from './IconTextLarge.module.scss';

const IconTextLarge = ({ success = false, header, message }) => {
  return (
    <>
      {success === false ? (
        <ErrorIcon className={styles.icon} />
      ) : (
        <SuccessIcon className={styles.icon} />
      )}
      {header && <p className={styles.header}>{header}</p>}
      <p className={styles.message}>{message}</p>
    </>
  );
};

export default IconTextLarge;
