import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell } from 'components/DenseTable/TableCell';
import TableRow from 'components/DenseTable/TableRow';
import DenseTable from 'components/DenseTable/DenseTable';
import { useAuthUser } from 'utils/AuthUser';

export default function PersonalInfoDenseTable() {
  const { user } = useAuthUser();
  const { t, ready } = useTranslation(['translation', 'profile']);

  if (!ready) return null;

  const baseName = 'profile:';
  const headerTitles = [
    { key: 0, value: t('translation:field') },
    { key: 1, value: t('translation:value') },
  ];

  return (
    <DenseTable headerTitles={headerTitles}>
      <TableRow key={user?._id + '0'}>
        <TableCell>{t(`${baseName}fullName`)}</TableCell>
        <TableCell>{user?.fullName}</TableCell>
      </TableRow>
      <TableRow key={user?._id + '1'}>
        <TableCell>{t(`${baseName}email`)}</TableCell>
        <TableCell>{user?.email}</TableCell>
      </TableRow>
      <TableRow key={user?._id + '2'}>
        <TableCell>{t(`${baseName}role`)}</TableCell>
        <TableCell>{t(`${baseName}${user?.role}`)}</TableCell>
      </TableRow>
      {user?.role === 'user' ? (
        <TableRow key={user?._id + '3'}>
          <TableCell>{t(`${baseName}corporateName`)}</TableCell>
          <TableCell>{user?.corporateName}</TableCell>
        </TableRow>
      ) : null}
    </DenseTable>
  );
}
