import apiBlob from 'api/apiBlob';
import api from './api';

const updateCampaignStatus = (data) => {
  return api.put('/approvals/campaigns/status', data);
};

const updateUserStatus = (data) => {
  return api.put('/approvals/users/status', data);
};

const updateCouponStatus = (data) => {
  return api.put('/approvals/coupons/status', data);
};

const getApprovalsOffersExcelFile = () => {
  return apiBlob.get(`/approvals/campaigns/excel`, {
    responseType: 'blob',
  });
};

const getApprovalsCouponsExcelFile = () => {
  return apiBlob.get(`/approvals/coupons/excel`, {
    responseType: 'blob',
  });
};

const getApprovalsUsersExcelFile = () => {
  return apiBlob.get(`/approvals/users/excel`, {
    responseType: 'blob',
  });
};

export {
  updateCampaignStatus,
  updateUserStatus,
  updateCouponStatus,
  getApprovalsOffersExcelFile,
  getApprovalsCouponsExcelFile,
  getApprovalsUsersExcelFile,
};
