import tables from 'data/tables/config';

export const getTableColumns = (tableType, titles) => {
  const config = tables[tableType];
  const columns = Object.keys(config).map((key) => {
    const column = {
      field: key,
      headerName: titles[key],
      valueFormatter: config[key].valueFormatter,
      valueGetter: config[key].valueGetter,
      renderCell: config[key].renderCell,
      headerAlign: config[key].headerAlign || 'center',
      align: config[key].align || 'center',
      sortable: config[key].sortable || false,
      filterable: config[key].filterable || false,
      width: config[key].width || 160,
      hide: config[key].hide || false,
    };
    if (config[key].sortComparator) {
      column.sortComparator = config[key].sortComparator;
    }
    return column;
  });
  return columns;
};

export const getTableRows = (data) => {
  const rows = data.map((row, index) => {
    return { id: index, ...row };
  });
  return rows;
};

export const getTableFunction = (functions, functionName) => {
  if (functions.length === 1) {
    return functions[0];
  } else {
    return functions.filter((fn) => fn.name === functionName)[0];
  }
};

export const sortDate = (a, b) => {
  a = a.split('/').reverse().join('');
  b = b.split('/').reverse().join('');
  return a > b ? 1 : a < b ? -1 : 0;
};
