import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ErrorIcon.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/CancelIcon.svg';

import styles from './GeneralMessageBox.module.scss';

const GeneralMessageBox = ({
  title,
  message,
  show = true,
  containerClass = '',
  ableToClose = true,
  closeBox,
  success = false,
  icon,
}) => {
  const { t, ready } = useTranslation(['translation']);
  const titleName =
    success === false ? 'translation:attention' : 'translation:completion';

  return show ? (
    <span
      style={{ display: 'block' }}
      className={`${styles.container} ${containerClass}`}
    >
      {icon ? (
        icon
      ) : success === false ? (
        <ErrorIcon className={styles.icon} />
      ) : (
        <SuccessIcon className={styles.icon} />
      )}
      {ableToClose ? (
        <IconButton
          className={styles.cancelIcon}
          aria-label="close"
          disableRipple
          disableFocusRipple={true}
          onClick={closeBox}
        >
          <CancelIcon />
        </IconButton>
      ) : (
        ''
      )}
      <span className={styles.section}>
        <span className={styles.header}>
          {title ? title : ready ? t(titleName) : ''}
        </span>
        {message}
      </span>
    </span>
  ) : null;
};

export default GeneralMessageBox;
