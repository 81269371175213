import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

import { getTableColumns } from 'utils/tablesUtils';
import TableContainer from 'components/TableContainer/TableContainer';
import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import useParticipants from 'data/useParticipants.js';
import downloadExcel from 'utils/downloadExcel';
import useDialog from 'hooks/useDialog';
import { getParticipantsExcelFile } from 'api/campaigns';

import styles from '../../styles/components/pagesWithTable.module.scss';

const OfferParticipants = ({ children }) => {
  const { t, ready } = useTranslation(['participants', 'translation']);

  const translation = ready
    ? {
        pageTitle: t(`participants:pageTitle`),
        titles: t(`participants:titles`, {
          returnObjects: true,
        }),
        errorButton: t(`translation:retry`),
      }
    : '';

  const { campaignId } = useParams();
  const { rows, loading } = useParticipants(campaignId);
  const columns = ready
    ? getTableColumns('participants', translation.titles)
    : [];

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestExcel = async () => {
    startLoading();
    try {
      const response = await getParticipantsExcelFile(campaignId);
      downloadExcel(
        response.data,
        `Campaign ID_${campaignId} - Participants List`
      );
      stopLoading();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={loading}
        pageName="OfferParticipants"
      >
        <h1
          className={styles.title}
        >{`${translation?.pageTitle} ${campaignId}`}</h1>
        <div className={styles.buttonsContainer}>
          <PrimaryButtonLoader
            className={styles.button}
            aria-label={translation?.downloadButtonLabel}
            title={translation?.downloadButtonLabel}
            onClick={requestExcel}
            loading={isLoading}
          >
            <GetAppIcon />
          </PrimaryButtonLoader>
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation?.downloadButtonLabel}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default OfferParticipants;
