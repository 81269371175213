import { PrimaryButton } from 'components/Buttons/Buttons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = () => {
  const { t, ready } = useTranslation(['datagrid']);
  const error = ready ? t('datagrid:tableError') : '';
  const label = ready ? t('datagrid:refresh') : '';

  const onRefresh = () => {
    window.location.reload(false);
  };

  return (
    <>
      <p>{error}</p>
      <PrimaryButton
        className={styles.button}
        title={label}
        aria-label={label}
        onClick={onRefresh}
      >
        {label}
      </PrimaryButton>
    </>
  );
};

export default ErrorMessage;
