import { toMomentObject } from 'react-dates';
import { formatUnixDate, dateToUTC, dateToGreek } from 'utils/dateUtils';
import moment from 'moment';

export const formOfferObject = (data) => {
  try {
    const { startDate, endDate } = data?.DateRangePicker || {};

    if (!Boolean(data?.corporateId)) delete data.corporateId;
    if (!Boolean(data?.corporateName)) delete data.corporateName;

    return {
      ...data,
      alphaBonusPoints: data?.alphaBonusPoints || 0,
      beginDate: dateToUTC(moment(dateToGreek(startDate)).startOf('day')),
      endDate: dateToUTC(moment(dateToGreek(endDate)).endOf('day')),
    };
  } catch (error) {
    return false;
  }
};

export const formActiveOfferObject = (formData, offerObject) => {
  const formedObject = formOfferObject(formData);
  return {
    couponsType: offerObject?.couponsType,
    offerId: offerObject?.offerId,
    corporateName: offerObject?.corporateName,
    corporateId: offerObject?.corporateId,
    alphaBonusPoints: offerObject?.alphaBonusPoints,
    name: formedObject?.name,
    name_en: formedObject?.name_en,
    description: formedObject?.description,
    description_en: formedObject?.description_en,
    beginDate: formedObject?.beginDate,
    endDate: formedObject?.endDate,
    totalCoupons: offerObject?.totalCoupons,
    couponsNotificationLimit: offerObject?.couponsNotificationLimit,
  };
};

export const formCampaignsCreateCouponsObject = (data) => {
  try {
    const { DateRangePicker, campaignId } = data || {};
    const { startDate, endDate } = DateRangePicker || {};

    return {
      ...data,
      beginDate: dateToUTC(moment(dateToGreek(startDate)).startOf('day')),
      endDate: dateToUTC(moment(dateToGreek(endDate)).endOf('day')),
      campaignId: parseInt(campaignId),
    };
  } catch (error) {
    return false;
  }
};

export const getOfferObject = (object) => {
  const {
    couponsType,
    offerId,
    corporateName,
    corporateId,
    alphaBonusPoints,
    name,
    name_en,
    description,
    description_en,
    beginDate,
    endDate,
    totalCoupons,
    couponsNotificationLimit,
  } = object || {};
  return {
    couponsType,
    offerId,
    corporateName,
    corporateId,
    alphaBonusPoints,
    name,
    name_en,
    description,
    description_en,
    beginDate,
    endDate,
    totalCoupons,
    couponsNotificationLimit,
  };
};

export const deformOfferObject = (campaignObject) => {
  const offerObject = getOfferObject(campaignObject);
  try {
    const { beginDate, endDate } = offerObject;
    return {
      ...offerObject,
      DateRangePicker: {
        startDate: toMomentObject(new Date(beginDate * 1000)),
        endDate: toMomentObject(new Date(endDate * 1000)),
      },
    };
  } catch (error) {
    return false;
  }
};

const translateCouponsType = (object) => {
  const couponsTypes = {
    DEFAULT: 'Εξαργύρωση Πόντων',
    POINTS_COLLECTION: 'Απόδοση Πόντων',
  };

  if (object) {
    object.couponsType = couponsTypes[object?.couponsType];
  }

  return object;
};

export const singleCampaignAsList = (data) => {
  const offerObject = translateCouponsType(
    getDatesAsUnix(getOfferObject(data))
  );

  const list = [];
  if (offerObject) {
    Object.keys(offerObject).forEach((key) => {
      list.push({
        field: offerFieldTranlationString[key],
        value: offerObject[key] || '-',
      });
    });
  }
  return list;
};

export const dataWithApprovalFieldsAsList = (data) => {
  const fieldsToUpdate = data?.approval?.fieldsToUpdate || {};
  const offerObject = translateCouponsType(
    getDatesAsUnix(getOfferObject(data))
  );

  const fieldsToUpdateFormed = getDatesAsUnix(fieldsToUpdate);
  const list = [];
  if (offerObject) {
    Object.keys(offerObject).forEach((key) => {
      const isApprovalField = fieldsToUpdateFormed.hasOwnProperty(key);
      list.push({
        field: offerFieldTranlationString[key],
        value: isApprovalField
          ? fieldsToUpdateFormed[key]
          : offerObject[key] || '-',
        isApprovalField,
      });
    });
  }
  return list;
};

export const offerFieldTranlationString = {
  couponsType: 'couponsType',
  offerId: 'offer-id',
  corporateName: 'coworker-name',
  corporateId: 'corporate-id',
  alphaBonusPoints: 'alpha-bonus-points',
  name: 'offer-name',
  name_en: 'offer-name-en',
  description: 'offer-description',
  description_en: 'offer-description-en',
  beginDate: 'start-date',
  endDate: 'end-date',
  totalCoupons: 'coupon-number',
  couponsNotificationLimit: 'coupon-limit',
};

const getDatesAsUnix = (offerObject) => {
  const { beginDate, endDate } = offerObject || {};
  if (offerObject?.beginDate) {
    offerObject.beginDate = formatUnixDate(beginDate, 'dd/MM/yyyy');
  }
  if (offerObject?.endDate) {
    offerObject.endDate = formatUnixDate(endDate, 'dd/MM/yyyy');
  }
  return offerObject;
};
