import React from 'react';
import { useTranslation } from 'react-i18next';

import InnerLoader from 'components/Buttons/InnerLoader';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import IconTextLarge from 'components/IconText/IconTextLarge';
import { colors } from 'utils/materialTheme';

import styles from './DialogContentFallbackWrapper.module.scss';

const darkBlue = colors.darkBlue;
const size = 64;

const DialogContentFallbackWrapper = ({
  error,
  getRequestLoading,
  retryGetRequest,
  retryButtonLoading,
  children,
}) => {
  const { t, ready } = useTranslation(['translation']);
  return (
    <article className={styles.article}>
      {getRequestLoading ? (
        <section className={styles.spinnerSection}>
          <InnerLoader loading={true} size={size} color={darkBlue} />
        </section>
      ) : null}
      {error && !getRequestLoading ? (
        <section className={styles.errorSection}>
          <IconTextLarge
            success={false}
            header={ready ? t('translation:attention') : ''}
            message={ready ? t('translation:something-wrong') : ''}
          />
          <section className={styles.button}>
            <PrimaryButtonLoader
              onClick={retryGetRequest}
              loading={retryButtonLoading}
              disabled={retryButtonLoading}
            >
              {ready ? t('translation:retry') : ''}
            </PrimaryButtonLoader>
          </section>
        </section>
      ) : null}
      {!error && !getRequestLoading ? children : null}
    </article>
  );
};

export default DialogContentFallbackWrapper;
