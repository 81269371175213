import React from 'react';
import { useTranslation } from 'react-i18next';

import { StandardInput } from './Inputs';
import { useWatch } from 'react-hook-form';

const TextInputCharacterLimit = ({
  register = () => {},
  errors = {},
  label,
  required = true,
  name = 'text',
  maxLength = 500,
  minLength = 0,
  defaultValue,
  control,
  ...rest
}) => {
  const { t, ready } = useTranslation(['inputs']);
  const baseName = 'inputs:errors';

  const value = useWatch({
    control,
    name: name,
    defaultValue: defaultValue,
  });

  return (
    <StandardInput
      inputRef={register({
        required: {
          value: required,
          message: ready ? t(`${baseName}.required`) : '',
        },
        maxLength: {
          value: maxLength,
          message: ready
            ? t(`${baseName}.maxLengthNumber`, { max: maxLength })
            : '',
        },
        minLength: {
          value: minLength,
          message: ready
            ? t(`${baseName}.minLengthNumber`, { min: minLength })
            : '',
        },
      })}
      label={label}
      name={name}
      type="text"
      errorMessage={errors[name]?.message}
      error={!!errors[name]}
      helperText={
        errors?.[name]?.message
          ? errors?.[name]?.message
          : `${value ? value.length : 0}/${maxLength}`
      }
      multiline
      {...rest}
    />
  );
};

export { TextInputCharacterLimit };
