import React from 'react';

import styles from './LoginWidget.module.scss';

const LoginWidget = ({ children, containerClass = '' }) => {
  return (
    <section className={`${styles.section} ${containerClass}`}>
      {children}
    </section>
  );
};

export default LoginWidget;
