import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';

import usePartners from 'data/usePartners';

import styles from './UserPartnerSelect.module.scss';

const UserPartnerSelect = ({
  errors,
  control,
  name = 'corporateId',
  defaultValue = null,
}) => {
  const { t, ready } = useTranslation(['users', 'inputs']);
  const label = ready ? t(`users:choose-user-partner`) : '';

  const { rows } = usePartners();

  const defaultProps = {
    options: rows,
    getOptionLabel: (option) => `${option?.name} - ${option?.corporateId}`,
  };

  const getValue = (value) => {
    return rows.filter((option) => option.corporateId === value)[0];
  };

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          classes={{
            root: styles.MuiAutoCompleteRoot,
            listbox: styles.MuiListboxComponent,
          }}
          {...defaultProps}
          value={props.value ? getValue(props.value) : null}
          onChange={(event, data) => {
            props.onChange(data?.corporateId);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={!!errors[name] ? t('inputs:errors.required') : ''}
            />
          )}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: { value: true, message: t('inputs:errors.required') },
      }}
    />
  );
};

export default UserPartnerSelect;
