const numberPattern = /^[0-9]*$/;

const validateOrShowError = (value, validateFunction, errorMessage) =>
  !validateFunction(value) ? errorMessage : true;

const validateOrShowMultipleErrors = (value, list) => {
  for (let i = 0; i < list.length; i++) {
    const { validateFunction, errorMessage } = list[i];
    if (!validateFunction(value)) {
      return errorMessage;
    }
  }
  return true;
};

const validateHasAtLeastOneSymbol = (value) =>
  value && /.*[!#$%^&*()+[\]{}?:.].*/.test(value);

const validateDoesNotContainSpecialSymbols = (value) =>
  value && /^[^<>~;`]+$/.test(value);

const validateIsEmail = (value) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

const validateTaxId = (taxId) => {
  if (!taxId || !taxId.match(/^\d{9}$/) || taxId === '000000000') {
    return false;
  }

  let m = 1;
  let sum = 0;
  for (let i = 7; i >= 0; i--) {
    m *= 2;
    sum += taxId.charAt(i) * m;
  }

  return (sum % 11) % 10 === parseInt(taxId.charAt(8), 10);
};

const validateGreekMobilePhone = (value) =>
  value && /^(\+30|0030)?69\d{8}$/.test(value);

const validateGreekLandlinePhone = (value) =>
  value && /^(\+30|0030)?(2)\d{9}$/.test(value);

const validateGreekPhone = (value) =>
  validateGreekMobilePhone(value) || validateGreekLandlinePhone(value);

const validateGreekMobilePhoneNoPrefix = (value) =>
  value && /^69\d{8}$/.test(value);

const validateGreekLandlinePhoneNoPrefix = (value) =>
  value && /^2\d{9}$/.test(value);

const validateGreekPhoneNoPrefix = (value) =>
  validateGreekMobilePhone(value) || validateGreekLandlinePhone(value);

const validateSmallerThan = (value, biggerValue, totalAmount, errorMessage) =>
  value >= biggerValue && value <= totalAmount ? true : errorMessage;

const validateDateRangePicker = (value) => {
  let isValidated = true;
  if (!value) return false;
  Object.keys(value).forEach((key) => {
    if (!value?.[key]) {
      isValidated = false;
    }
  });
  return isValidated;
};

const characters = '$<>@#^[]{}?:;|\'\\",~`=.';
const validateCouponPrefix = (value) => {
  for (let i = 0; i < characters.length; i++) {
    if (value.indexOf(characters[i]) > -1) {
      return false;
    }
  }
  return true;
};

export {
  validateCouponPrefix,
  validateOrShowError,
  validateOrShowMultipleErrors,
  validateTaxId,
  validateGreekMobilePhone,
  validateGreekLandlinePhone,
  validateGreekPhone,
  validateGreekMobilePhoneNoPrefix,
  validateGreekLandlinePhoneNoPrefix,
  validateGreekPhoneNoPrefix,
  validateSmallerThan,
  validateHasAtLeastOneSymbol,
  validateDoesNotContainSpecialSymbols,
  validateIsEmail,
  validateDateRangePicker,
  numberPattern,
};
