import React from 'react';

import IconText from 'components/IconText/IconText';

import styles from './SidebarElement.module.scss';

const SidebarElement = ({
  Icon,
  content,
  iconWeight,
  iconClass = styles.icon,
  contentClass = styles.content,
}) => {
  return (
    <div className={styles.container}>
      <IconText
        Icon={Icon}
        content={content}
        weight={iconWeight}
        iconClass={iconClass}
        contentClass={contentClass}
      />
    </div>
  );
};

export default SidebarElement;
