import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

import styles from './DialogOverlay.module.scss';

const DialogOverlay = ({
  open = true,
  closeDialog,
  children,
  dialogActions,
  dialogHeader,
  showDialogTitle = true,
  dialogClassname = '',
  dialogTitleClassname = '',
  dialogHeaderClassname = '',
  dialogContentClassname = '',
  dialogActionsClassname = '',
  iconButtonClassname = styles.iconButton,
  disableBackdropClick = false,
  showCloseButton = true,
  scroll,
  showBackButton = false,
  goBack = () => {},
}) => {
  const dialogClasses = {
    paper: `${styles['dialog']} ${dialogClassname}`,
    container: `${styles['container']}`,
  };

  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      open={open}
      onClose={closeDialog}
      maxWidth={false}
      classes={dialogClasses}
      scroll={scroll}
    >
      {showDialogTitle ? (
        <DialogTitle
          className={`${styles.dialogTitle} ${dialogTitleClassname}`}
          disableTypography
        >
          {showBackButton && (
            <IconButton
              className={styles.backButton}
              aria-label="close"
              onClick={goBack}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {dialogHeader && (
            <h1 className={`${styles.header} ${dialogHeaderClassname}`}>
              {dialogHeader}
            </h1>
          )}
          {showCloseButton && (
            <IconButton
              className={iconButtonClassname}
              aria-label="close"
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      ) : null}
      <DialogContent
        className={`${styles.dialogContent} ${dialogContentClassname}`}
      >
        {children}
      </DialogContent>
      {dialogActions && (
        <DialogActions
          className={`${styles.dialogActions} ${dialogActionsClassname}`}
        >
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogOverlay;
