import React from 'react';

import SidebarElement from 'components/SidebarElement/SidebarElement';

import styles from './SidebarButtonElement.module.scss';

const SidebarButtonElement = ({ Icon, content, iconWeight, ...rest }) => {
  return (
    <button className={styles.button} {...rest}>
      <SidebarElement Icon={Icon} content={content} iconWeight={iconWeight} />
    </button>
  );
};

export default SidebarButtonElement;
