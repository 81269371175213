import React from 'react';

import { ReactComponent as Avatar } from 'assets/icons/Avatar.svg';

import AvatarInitials from 'components/AuthorizedHeaderContent/AvatarInitials';

const AuthorizedHeaderAvatar = ({ user }) => {
  return user && user.firstName && user.lastName ? (
    <AvatarInitials name={user.firstName} lastname={user.lastName} />
  ) : (
    <Avatar />
  );
};

export default AuthorizedHeaderAvatar;
