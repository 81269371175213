import React from 'react';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';

import { getTableColumns } from 'utils/tablesUtils';
import TableContainer from 'components/TableContainer/TableContainer';
import FullWidthTableLayout from 'layouts/FullWidthTableLayout';
import useCoupons from 'data/useCoupons';
import CheckCouponForm from 'pages/coupons/CheckCouponForm';
import { PrimaryButtonLoader } from 'components/Buttons/Buttons';
import { getCouponsExcelFile } from 'api/coupons';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';
import useDialog from 'hooks/useDialog';
import downloadExcel from 'utils/downloadExcel';
import AwardedCouponForm from 'components/AwardedCouponForm';

import styles from '../../styles/components/pagesWithTable.module.scss';

const Coupons = ({ children }) => {
  const { t, ready } = useTranslation(['coupons', 'translation', 'datagrid']);
  const translation = ready
    ? {
        pageTitle: t(`coupons:pageTitle`),
        titles: t(`coupons:titles`, { returnObjects: true }),
        sorting: t(`datagrid:sortingDisclaimer`, { stars: '*' }),
        errorButton: t(`translation:retry`),
        downloadButtonLabel: t(`coupons:downloadButtonLabel`),
      }
    : {};

  const { rows, loading: tableLoading } = useCoupons();
  const columns = ready ? getTableColumns('coupons', translation?.titles) : [];

  const {
    isOpen,
    isLoading,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog(false);

  const requestExcel = async () => {
    startLoading();
    try {
      const response = await getCouponsExcelFile();
      downloadExcel(response.data, `Coupons List`);
      stopLoading();
    } catch (error) {
      openDialog();
      try {
        handleError(
          JSON.parse(await error.response?.data?.text())?.error?.userMessage
        );
      } catch (error) {
        handleError(translation?.somethingWrong);
      }
    }
  };

  return (
    <FullWidthTableLayout ready={columns.length > 0}>
      <TableContainer
        tableData={{
          columns,
          rows,
        }}
        loading={tableLoading}
        disclaimerElements={[
          <span key={1}>{translation?.sorting}</span>,
          <span key={2}>{translation?.dates}</span>,
        ]}
        pageName="Coupons"
      >
        <h1 className={styles.title}>{translation?.pageTitle}</h1>
        <div
          className={`${styles.buttonsContainer} ${styles.fullWidthButtonsContainer}`}
        >
          <CheckCouponForm />
          <PrimaryButtonLoader
            className={styles.button}
            aria-label={translation?.downloadButtonLabel}
            title={translation?.downloadButtonLabel}
            onClick={requestExcel}
            loading={isLoading}
          >
            <GetAppIcon />
          </PrimaryButtonLoader>
          <AwardedCouponForm className={styles.rightAligned} />
        </div>
      </TableContainer>
      {children}
      <ResponseMessageDialog
        open={isOpen}
        dialogActions={
          <PrimaryButtonLoader onClick={requestExcel}>
            {translation?.errorButton}
          </PrimaryButtonLoader>
        }
        closeDialog={closeDialog}
        dialogHeader={translation.downloadButtonLabel}
        isLoading={isLoading}
        success={false}
      >
        {dialogErrorMessage}
      </ResponseMessageDialog>
    </FullWidthTableLayout>
  );
};

export default Coupons;
