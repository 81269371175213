import authStorage from 'utils/storage/authStorage';

export const apiConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Appid: process.env.REACT_APP_APPID,
  },
};

export const requestSuccess = (config) => {
  const token = authStorage.getToken();
  if (!token) {
    if (config.headers.common && config.headers.common['Authorization'])
      delete config.headers.common['Authorization'];
  } else {
    config.headers.common['Authorization'] = `Bearer ${authStorage.getToken()}`;
  }
  return config;
};

export const requestError = (error) => {
  return Promise.reject(error);
};

export const asyncInterval = async (
  requestParameters,
  callback,
  ms,
  triesLeft = 12,
  runImmediately = true
) => {
  return new Promise(async (resolve, reject) => {
    if (runImmediately) {
      if (await callback(requestParameters)) {
        resolve();
        return;
      } else if (triesLeft <= 1) {
        reject();
        return;
      }
      triesLeft--;
    }
    const interval = setInterval(async () => {
      if (await callback(requestParameters)) {
        resolve();
        clearInterval(interval);
        return;
      } else if (triesLeft <= 1) {
        reject();
        clearInterval(interval);
        return;
      }
      triesLeft--;
    }, ms);
  });
};
