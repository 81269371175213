import React from 'react';
import { useTranslation } from 'react-i18next';

import GeneralMessageDialog from 'components/GeneralMessageDialog/GeneralMessageDialog';
import { PrimaryButton } from 'components/Buttons/Buttons';
import ResponseMessageDialog from 'components/ResponseMessageDialog/ResponseMessageDialog';

import styles from './ConfirmationDialog.module.scss';

const ConfirmationDialog = ({
  open,
  closeDialog,
  dialogHeader,
  request,
  denyButtonLabel = '',
  acceptButtonLabel = '',
  errorButtonLabel = '',
  okButtonLabel = '',
  error,
  errorMessage,
  isLoading,
  children,
}) => {
  const { t, ready } = useTranslation(['translation']);

  const handleRequest = () => {
    request();
  };

  const translation = ready
    ? {
        denyButton: denyButtonLabel || t('translation:no'),
        acceptButton: acceptButtonLabel || t('translation:yes'),
        errorButton: errorButtonLabel || t('translation:retry'),
        okButton: okButtonLabel || t('translation:close'),
      }
    : '';

  const errorMessageContent = errorMessage
    ? errorMessage
    : ready
    ? t('translation:something-wrong')
    : '';

  return (
    <>
      {error ? (
        <ResponseMessageDialog
          open={open}
          closeDialog={closeDialog}
          dialogHeader={dialogHeader}
          dialogActions={
            <>
              <PrimaryButton
                onClick={handleRequest}
                disabled={isLoading}
                className={styles.secondaryButton}
              >
                {translation.errorButton}
              </PrimaryButton>
              <PrimaryButton onClick={closeDialog} disabled={isLoading}>
                {translation.okButton}
              </PrimaryButton>
            </>
          }
          isLoading={isLoading}
        >
          {errorMessageContent}
        </ResponseMessageDialog>
      ) : (
        <GeneralMessageDialog
          open={open}
          closeDialog={closeDialog}
          dialogActions={
            <>
              <PrimaryButton onClick={closeDialog} disabled={isLoading}>
                {translation.denyButton}
              </PrimaryButton>
              <PrimaryButton onClick={handleRequest} disabled={isLoading}>
                {translation.acceptButton}
              </PrimaryButton>
            </>
          }
          dialogHeader={dialogHeader}
          isLoading={isLoading}
        >
          {children}
        </GeneralMessageDialog>
      )}
    </>
  );
};

export default ConfirmationDialog;
