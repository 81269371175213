import React from 'react';
import { useTranslation } from 'react-i18next';

import StandardSelect from 'components/Selects/StandardSelect';
import StandardSelectOption from 'components/Selects/StandardSelectOption';
import { roleSelectOptions } from 'utils/users/usersUtils';
import SelectFormControl from 'components/Selects/SelectFormControl';
import { Controller } from 'react-hook-form';
import SelectLabel from 'components/Selects/SelectLabel';

import styles from './UserRoleSelect.module.scss';
import { FormHelperText } from '@material-ui/core';

const UserRoleSelect = ({
  errors,
  control,
  name = 'role',
  defaultValue = '',
}) => {
  const { t, ready } = useTranslation(['users', 'inputs']);

  if (!ready) return null;

  return (
    <SelectFormControl classes={{ root: styles.MuiFormControlRoot }}>
      <SelectLabel>{t('users:choose-user-role')}</SelectLabel>
      <Controller
        as={
          <StandardSelect>
            {roleSelectOptions.map((option) => {
              return (
                <StandardSelectOption key={option?.key} value={option?.value}>
                  {t(option?.tranaslationText)}
                </StandardSelectOption>
              );
            })}
          </StandardSelect>
        }
        name={name}
        error={!!errors[name]}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: { value: true, message: t('inputs:errors.required') },
        }}
      />
      {!!errors[name] && (
        <FormHelperText error={true}>{errors[name].message}</FormHelperText>
      )}
    </SelectFormControl>
  );
};

export default UserRoleSelect;
